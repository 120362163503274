import { useNavigate } from "react-router-dom";
import {
  icEmail,
  icFacebook,
  icInstagram,
  icLocation,
  icTiktok,
  qSkipperLogoWhite,
} from "../../utils/Images";
import "./Footer.style.scss";
import footerMail from "../../assets/svg-icons/email-icon-footer-white.svg";
import LocationIcon from "../../assets/svg-icons/location-icon-footer-white.svg";
import TiktokIcon from "../../assets/svg-icons/tiktok-icon-footer.svg";
import InstagramIcon from "../../assets/svg-icons/instagram-icon-footer.svg";
import FacebookIcon from "../../assets/svg-icons/facebook-icon-footer.svg";

interface Props {}

const Footer = ({}: Props) => {
  const navigate = useNavigate();
  const homeRedirection = () => {
    navigate("/barber", { replace: true });
  };
  return (
    <footer className="page-footer font-small blue pt-4">
      <div className="Main-footerBottom mt-4">
        <div className="container text-center text-md-left">
          <div className="row">
            <div className="col-md-3 mt-md-0 mt-3">
              <div className="footer-section">
                <img
                  className="footer-logo"
                  src={qSkipperLogoWhite}
                  alt=""
                  onClick={() => homeRedirection()}
                />
                <div className="footerSocail-section">
                  <a
                    href="https://www.tiktok.com/@qskipper?lang=en"
                    target="_blank"
                  >
                    <img
                      className="footerFacebook-icon"
                      src={TiktokIcon}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/qskipperapp"
                    target="_blank"
                  >
                    <img className="InstagramIcon" src={InstagramIcon} alt="" />
                  </a>
                  <a
                    href="https://www.facebook.com/QSkipper-104091511768946"
                    target="_blank"
                  >
                    <img className="FacebookIcon" src={FacebookIcon} alt="" />
                  </a>
                </div>
              </div>
            </div>
            {/* <!-- Grid column --> */}

            <hr className="clearfix w-100 d-md-none pb-3" />

            {/* <!-- Grid column --> */}
            <div className="col-md-2 mb-md-0 mb-3">
              {/* <!-- Links --> */}
              <ul className="list-unstyled">
                <li>
                  <a
                    style={{ textDecoration: "none" }}
                    className="footerEmail-heading"
                    href="mailto:info@qskipper.app"
                  >
                    <img className="email-icon" src={footerMail} alt="" />
                    info@qskipper.app
                  </a>
                </li>
                <li>
                  <a
                    style={{ textDecoration: "none" }}
                    className="footerLocation-heading"
                  >
                    <img
                      className="foterLcotion-icon"
                      src={LocationIcon}
                      alt=""
                    />
                    <span>London, UK</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-1"></div>
            {/* <!-- Grid column --> */}

            {/* <!-- Grid column --> */}
            <div className="col-md-2 mb-md-0 mb-3">
              {/* <!-- Links --> */}
              <ul className="list-unstyled">
                <li>
                  <a
                    style={{ textDecoration: "none" }}
                    className="footerTerms-heading"
                    href="https://qskipper.app/terms-and-conditions"
                    target="_blank"
                  >
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a
                    style={{ textDecoration: "none" }}
                    className="footerdisclaimer-heading"
                    href="https://qskipper.app/disclaimer"
                    target="_blank"
                  >
                    Disclaimer
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-2 mb-md-0 mb-3">
              {/* <!-- Links --> */}

              <ul className="list-unstyled">
                <li>
                  <a
                    style={{ textDecoration: "none" }}
                    className="footerReturn-heading"
                    href="https://qskipper.app/return-policy"
                    target="_blank"
                  >
                    Refund Policy
                  </a>
                </li>
                <li>
                  <a
                    style={{ textDecoration: "none" }}
                    className="footerCookie-heading"
                    href="https://qskipper.app/cookie-policy"
                    target="_blank"
                  >
                    Cookie Policy
                  </a>
                </li>
              </ul>
            </div>
            {/* <!-- Grid column --> */}
            <div className="col-md-2 mb-md-0 mb-3">
              {/* <!-- Links --> */}

              <ul className="list-unstyled">
                <li>
                  <a
                    style={{ textDecoration: "none" }}
                    className="footerPrivacy-heading"
                    href="https://qskipper.app/privacy-notice"
                    target="_blank"
                  >
                    Privacy Notice
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- Grid row --> */}
          <hr className="FooterHR mt-5" />
          {/* <!-- Copyright --> */}
        </div>
      </div>
      <div className="footer-copyright text-center py-3 pt-1">
        Copyright © {new Date().getFullYear()} - QSkipper
      </div>
      {/* <!-- Footer Links --> */}
    </footer>
  );
};

export default Footer;
