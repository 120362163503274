import { useState, useEffect, useRef } from "react";

var autoComplete: {
  addListener: (arg0: string, arg1: () => void) => void;
  getPlace: () => any;
};

declare global {
  interface Window {
    google: any;
  }
}



function SearchLocationInput(props: any) {
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);


  const loadScript = (url: any, callback: any) => {
    let script = document.createElement("script") as any;
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  function handleScriptLoad(updateQuery: any, autoCompleteRef: any) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current
    );

    autoComplete.addListener("place_changed", function () {
      handlePlaceSelect(updateQuery);
      let place = autoComplete.getPlace();
      props.setLocationObj(place);
      props.locationRef.current = autoCompleteRef.current;
    });
  }

  async function handlePlaceSelect(updateQuery: any) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
  }

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  const handleChange = (e: any) => {
    setQuery(e)
  }

  return (
    <div >
      <input
        className="search-input-field search-input"
        ref={autoCompleteRef}
        onChange={(event) => handleChange(event.target.value)
        }
        placeholder="Search by location"
        value={query}
      />
    </div>
  );
}

export default SearchLocationInput;
