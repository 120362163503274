import StarRatingComponent from "react-star-rating-component";
import { Line } from "rc-progress";
import "./ShopDetailsRatings.style.scss";
import { icRatingProgress2 } from "../../../utils/Images";

interface Props {
  data: any;
}
export const getInPercent = (num: number) => {
  return num + 0;
};
export const ratingConvert = (num: any) => {
  return parseFloat(num);
};
const ShopDetailsRatings = ({ data }: Props) => {
  return (
    <div className="main-Footer">
      {data?.reviews?.overAllRatingCount.length > 0 ? (
        <>
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 test">
                <div className="FooterRating-section">
                  <div className="fotterRating-part">
                    <h2 className="FooterRating-heading"> Rating</h2>
                  </div>
                  <div className="progressBar-section mt-4">
                    {data?.reviews?.overAllRatingCount.map(
                      (item: any, index: number) => {
                        const { one, two, three, four, five } = item;

                        return (
                          <div key={index}>
                            <div className="rating-number">
                              <h6 className="fifth-rating">5</h6>
                              <span className="progressbar-5">
                                {getInPercent(five) === 0 ? (
                                  <img
                                    className="entyLine"
                                    src={icRatingProgress2}
                                  />
                                ) : (
                                  <Line
                                    className="progress-line"
                                    percent={getInPercent(five)}
                                    strokeWidth={2}
                                    strokeColor="#fdcc0d"
                                  />
                                )}
                              </span>
                            </div>
                            <div className="rating-number">
                              <h6 className="fourth-rating">4</h6>
                              <span className="progressbar-4">
                                {getInPercent(four) === 0 ? (
                                  <img
                                    className="entyLine"
                                    src={icRatingProgress2}
                                  />
                                ) : (
                                  <Line
                                    className="progress-line"
                                    percent={getInPercent(four)}
                                    strokeWidth={2}
                                    strokeColor="#fdcc0d"
                                  />
                                )}
                              </span>
                            </div>
                            <div className="rating-number">
                              <h6 className="third-rating">3</h6>
                              <span className="progressbar-3">
                                {getInPercent(three) === 0 ? (
                                  <img
                                    className="entyLine"
                                    src={icRatingProgress2}
                                  />
                                ) : (
                                  <Line
                                    className="progress-line"
                                    percent={getInPercent(three)}
                                    strokeWidth={2}
                                    strokeColor="#fdcc0d"
                                  />
                                )}
                              </span>
                            </div>
                            <div className="rating-number">
                              <h6 className="second-rating">2</h6>
                              <span className="progressbar-2">
                                {getInPercent(two) === 0 ? (
                                  <img
                                    className="entyLine"
                                    src={icRatingProgress2}
                                  />
                                ) : (
                                  <Line
                                    className="progress-line"
                                    percent={getInPercent(two)}
                                    strokeWidth={2}
                                    strokeColor="#fdcc0d"
                                  />
                                )}
                              </span>
                            </div>
                            <div className="rating-number">
                              <h6 className="first-rating">1</h6>
                              <span className="progressbar-1">
                                {getInPercent(one) === 0 ? (
                                  <img
                                    className="entyLine"
                                    src={icRatingProgress2}
                                  />
                                ) : (
                                  <Line
                                    className="progress-line"
                                    percent={getInPercent(one)}
                                    strokeWidth={2}
                                    strokeColor="#fdcc0d"
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 test">
                <div className="footerReviews-section">
                  <div className="footerReviews-part">
                    <h2 className="footerReviews-heading">View all Reviews</h2>
                    <h1 id="test" className="footerRating-">
                      {data?.reviews?.averageRating}
                    </h1>
                    {}
                    <span className="footer-stars-icon">
                      <StarRatingComponent
                        name="rate1"
                        starCount={5}
                        value={ratingConvert(data?.reviews?.averageRating)}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="ratingHR" />
        </>
      ) : null}
    </div>
  );
};
export default ShopDetailsRatings;
