import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ShopDetail, VerifyPath } from "../../routes/RouterPath";
import {
  GET_COUNTRY,
  LOGIN,
  SIGNUP,
  TERMS_AND_CONDITIONS,
} from "../../services/EndPoints";
import { HttpCallGet, HttpCallPost } from "../../services/NetworkCall";
import "./Login.style.scss";
import { ToastOnSuccess, ToastOnFailure } from "../../Shared/Toast/Toast";
import Spinner from "../../Shared/Spinner/Spinner";
import axios from "axios";
import UserIcon from "../../assets/user_icon1.png";
import UserAddimage from "../../assets/user_icon_camera.png";
import { getProviderIdFromUrl } from "../../utils/filter";

const Login = ({}) => {
  const search = useLocation().search;
  const tab = new URLSearchParams(search).get("tab");
  const selectedTab = tab && tab === "2" ? false : true;

  const [isLoginVisible, setIsLoginVisible] = useState(selectedTab);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countryList, setCountryList] = useState([{}]);
  const [mobileLogin, setMobileLogin] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");

  const [nameSignup, setNameSignup] = useState("");
  const [mobileSignup, setMobileSignup] = useState("");
  const [emailSignup, setEmailSignup] = useState("");
  const [passwordSignup, setPasswordSignup] = useState("");

  const [isSubmit, setIsSubmit] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>([]);
  const [locationImg, setLocationImg] = useState<any>("");

  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  if (sessionStorage.getItem("AUTHTOKEN_USER")) {
    sessionStorage.removeItem("AUTHTOKEN_USER");
  }

  if (sessionStorage.getItem("USER_ID")) {
    sessionStorage.removeItem("USER_ID");
  }

  useEffect(() => {
    getCountryList();
  }, []);

  const getCountryList = () => {
    HttpCallGet(GET_COUNTRY)
      .then((response: any) => {
        let countryArr: any = [];
        response.data.data.map((item: any) => {
          let countryObj: any = {};
          countryObj.label = item.name;
          countryObj.value = item.id;
          countryArr.push(countryObj);
        });
        setCountryList(countryArr.reverse());
      })
      .catch((error) => {});
  };

  const loginAPICall = (e:any) => {
    e.preventDefault()
    setIsSubmit(true);
    setPasswordLogin(passwordLogin.trim());

    if (
      mobileLogin &&
      passwordLogin &&
      mobileLogin.length >= 10 &&
      !isNaN(parseInt(mobileLogin))
    ) {
      setLoading(true);
      let data = {
        phoneNumber: mobileLogin,
        // timeZone: await TimeZone.getTimeZone().then(zone => zone),
        password: passwordLogin,
        deviceType: "Web",
        deviceToken: "123456",
      };
      HttpCallPost(LOGIN, data)
        .then((response: any) => {
          setLoading(false);
          if (response?.data?.status == 200) {
            setMobileLogin("");
            setPasswordLogin("");
            sessionStorage.setItem(
              "AUTHTOKEN_USER",
              response?.data?.data[0]?.authToken
            );
            sessionStorage.setItem("USER_ID", response?.data?.data[0]?.userId);
            ToastOnSuccess(response?.data?.msg);
            if (sessionStorage.getItem("previousUrl")) {
              navigate(`${sessionStorage.getItem("previousUrl")}`);
            } else {
              navigate(ShopDetail, { replace: true });
            }
          } else if (response?.data?.status == 201) {
            setMobileLogin("");
            setPasswordLogin("");
            ToastOnSuccess(response?.data?.msg);
            navigate(VerifyPath, {
              state: {
                customerForgotPassMobileNumber: mobileLogin,
                customerForgotPassID: response.data.userPhoneId,
                reDirectionUrl: "/login",
              },
              replace: true,
            });
          } else {
            ToastOnFailure(response?.data?.msg);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const signupAPICall = (e:any) => {
    e.preventDefault()
    setIsSubmit(true);
    if (
      nameSignup &&
      emailSignup &&
      mobileSignup &&
      passwordSignup &&
      selectedCountry &&
      validateEmail(emailSignup) &&
      mobileSignup.length >= 10 &&
      !isNaN(parseInt(mobileSignup))
    ) {
      setLoading(true);
      let data = {
        countryId: selectedCountry,
        phoneNumber: mobileSignup.replace(/\s+/g, ""),
        password: passwordSignup,
        name: nameSignup,
        profilePic: locationImg ? locationImg : "",
        email: emailSignup,
      };
      HttpCallPost(SIGNUP, data)
        .then((response: any) => {
          setLoading(false);
          if (response.data.status == 200) {
            setNameSignup("");
            setMobileSignup("");
            setPasswordSignup("");
            setEmailSignup("");
            setSelectedCountry("");
            setIsLoginVisible(true);
            setIsSubmit(false);
            ToastOnSuccess(response.data.msg);
            navigate(VerifyPath, {
              state: {
                customerForgotPassMobileNumber: mobileSignup,
                customerForgotPassID: response.data.userPhoneId,
                reDirectionUrl: "/login",
              },
              replace: true,
            });
          } else {
            ToastOnFailure(response.data.msg);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const forgotPasswordRedirection = () => {
    navigate("/forget", { replace: true });
  };

  const passwordShowHandler = (flag: boolean) => {
    setIsShowPassword(flag);
  };

  const phoneNumberCheck = (evt: any) => {
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return true && evt.preventDefault();

    return false && evt.preventDefault();
  };

  const spaceCheckForSignIn = (evt: any) => {
    if (evt.which === 32 && !passwordLogin.length) evt.preventDefault();
  };

  const spaceCheckForSignUp = (evt: any) => {
    if (evt.which === 32 && !passwordSignup.length) evt.preventDefault();
  };

  const validateEmail = (inputEmail: any) => {
    const re = /^[a-z0-9._]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    return re.test(inputEmail);
  };

  const resetData = () => {
    setIsSubmit(false);
  };

  const addParamsInUrl = (tab: any) => {
    navigate(`/login?tab=${tab}`, { replace: true });
    setIsShowPassword(false);
  };

  const handleInput = (event: any) => {
    setLoading(true);
    let fileData = [];
    for (let i = 0; i < event.target.files.length; i++) {
      fileData.push(event.target.files[i]);
    }
    setSelectedImage(fileData);
    uploadMultiPart(fileData);
  };

  const uploadMultiPart = (File: any) => {
    let percentagePerFile = [];
    let totalFileSize = 0;
    for (let i = 0; i < File.length; i++) {
      let perFileSize = (File[i]?.size * 100) / totalFileSize;
      percentagePerFile.push(perFileSize);
      uploadMedia(File[i], i, percentagePerFile, File.length);
    }
  };

  const uploadMedia = async (
    selectedFile: any,
    i: number,
    percentagePerFile: any,
    arrLength: number
  ) => {
    let extensionArr = selectedFile.name.split(".");
    let fileExtension = extensionArr[extensionArr.length - 1].toLowerCase(); //
    let contentTypeName = selectedFile.type;

    await axios
      .get(process.env.REACT_APP_BASE_URL + "start-upload", {
        params: {
          extension: fileExtension,
          contentType: contentTypeName,
        },
      })
      .then((response) => {
        if (response.data.status == 200) {
          let uploadId = response.data.data.UploadId;
          let fileName = response.data.data.Key;

          let multipartData = {
            selectedFile: selectedFile,
            uploadId: uploadId,
            fileName: fileName,
            i: i,
            percentagePerFile: percentagePerFile,
            arrLength: arrLength,
          };
          uploadMultipartFile(multipartData);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "error");
      });
  };

  const getChunkSize = async (fileSize: number) => {
    var FILE_CHUNK = 5242880; // 3MB
    if (fileSize > 31457280) {
      //30 mb
      FILE_CHUNK = Math.floor(fileSize / 6);
    }
    return FILE_CHUNK;
  };

  /** method that upload in multipart **/
  const uploadMultipartFile = async (multipartData: any) => {
    const FILE_CHUNK_SIZE = await getChunkSize(multipartData.selectedFile.size);
    const fileSize = multipartData.selectedFile.size;

    let NUM_CHUNKS = Math.floor(fileSize / FILE_CHUNK_SIZE);

    if (fileSize % FILE_CHUNK_SIZE > 0) {
      NUM_CHUNKS = NUM_CHUNKS + 1;
    }

    let promisesArray: any = [];
    let start, end, blob: any;

    for (let index = 1; index < NUM_CHUNKS + 1; index++) {
      start = (index - 1) * FILE_CHUNK_SIZE;
      end = index * FILE_CHUNK_SIZE;

      if (index == NUM_CHUNKS - 1) {
        var lastChunkSize = fileSize - end;
        if (lastChunkSize < 5242880) {
          // if chunksize is less than 5 MB then merge it in second last chunk
          NUM_CHUNKS = NUM_CHUNKS - 1;
        }
      }

      blob =
        index < NUM_CHUNKS
          ? multipartData.selectedFile.slice(start, end)
          : multipartData.selectedFile.slice(start);

      await axios
        .get(process.env.REACT_APP_BASE_URL + "get-presigned-url", {
          params: {
            fileName: multipartData.fileName,
            partNumber: index,
            uploadId: multipartData.uploadId,
          },
        })
        .then((response) => {
          if (response.data.status == 200) {
            let signedUrl = response.data.data;

            let uploadResponse = new Promise(function (resolve, reject) {
              axios
                .put(signedUrl, blob, {
                  headers: { "Content-Type": multipartData.selectedFile.type },
                })
                .then((res) => {
                  resolve(res);
                });
            });
            promisesArray.push(uploadResponse);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error, "error");
        });
    }

    let resolvedArray = await Promise.all(promisesArray);

    let uploadPartsArray: any = [];

    resolvedArray.forEach((resolvedPromise, index) => {
      uploadPartsArray.push({
        ETag: JSON.parse(resolvedPromise.headers.etag),
        PartNumber: index + 1,
      });
    });

    let data = {
      fileName: multipartData.fileName,
      parts: uploadPartsArray,
      uploadId: multipartData.uploadId,
    };

    let completeUploadResponse = await axios
      .post(process.env.REACT_APP_BASE_URL + "complete-upload", data)
      .then((response) => {
        setLoading(false);
        if (response.data.status === 200) {
          setLocationImg(getProviderIdFromUrl(response.data.data.Location));
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "error");
      });
  };

  return (
    <div
      className="container" /*style={{ minHeight: window.innerHeight - 180 }}*/
    >
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="signUp-section">
            <h2 className="continue-heading">
              {isLoginVisible ? "Log in" : "Sign up"}
            </h2>
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <div className="top-signin-login-tab">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li
                      className="nav-item"
                      role="presentation"
                      onClick={() => {
                        setIsLoginVisible(true);
                        resetData();
                        addParamsInUrl(1);
                      }}
                    >
                      <a
                        className={
                          isLoginVisible ? "nav-link active" : "nav-link"
                        }
                        id={
                          isLoginVisible ? "nav-active-tab" : "nav-inactive-tab"
                        }
                        data-toggle="tab"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        Log In
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      role="presentation"
                      onClick={() => {
                        setIsLoginVisible(false);
                        resetData();
                        addParamsInUrl(2);
                      }}
                    >
                      <a
                        className={
                          !isLoginVisible ? "nav-link active" : "nav-link"
                        }
                        id={
                          !isLoginVisible
                            ? "nav-active-tab"
                            : "nav-inactive-tab"
                        }
                        data-toggle="tab"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        Sign Up
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>

            <div className="tab-content" id="nav-tabContent">
              {/* <!-- log in page --> */}
              {isLoginVisible && (
                <div
                  className="tab-pane fade show active"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-inactive-tab"
                >
                  <form method="dialog" onSubmit={(e)=>loginAPICall(e)}>
                    <div className="form-group">
                      <label htmlFor="exampleInputPhone Number1">
                        Phone Number
                      </label>
                      <input
                        maxLength={11}
                        type="text"
                        className="form-control number"
                        id="exampleInputPhone Number1"
                        value={mobileLogin}
                        onKeyPress={(event) => phoneNumberCheck(event)}
                        onChange={(e) => {
                          setMobileLogin(e.target.value);
                        }}
                        autoComplete="off"
                        placeholder="Enter mobile number"
                      />
                      {isSubmit && mobileLogin.length == 0 ? (
                        <div style={{ color: "red" }}>
                          Please enter your mobile number.
                        </div>
                      ) : (
                        <></>
                      )}
                      {(isSubmit &&
                        mobileLogin.length > 0 &&
                        mobileLogin.length < 10) ||
                      (mobileLogin.length > 0 &&
                        isNaN(parseInt(mobileLogin))) ? (
                        <div style={{ color: "red" }}>
                          Please enter a valid phone number.
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Password</label>
                      <div className="password_div">
                        <input
                          maxLength={32}
                          type={!isShowPassword ? "password" : "text"}
                          className="form-control password"
                          id="exampleInputPassword1"
                          value={passwordLogin}
                          onKeyPress={(event) => spaceCheckForSignIn(event)}
                          onChange={(e) => {
                            setPasswordLogin(e.target.value);
                          }}
                          placeholder="*********"
                          autoComplete="off"
                        />
                        <span className="password_eye">
                          {!isShowPassword ? (
                            <i
                              className="fa fa-eye-slash"
                              onClick={() => passwordShowHandler(true)}
                            ></i>
                          ) : (
                            <i
                              className="fa fa-eye"
                              onClick={() => passwordShowHandler(false)}
                            ></i>
                          )}
                        </span>
                      </div>

                      {isSubmit && passwordLogin.length == 0 ? (
                        <div style={{ color: "red" }}>
                          Please enter password.
                        </div>
                      ) : (
                        <></>
                      )}
                      <h6
                        className="forgotPassword"
                        onClick={() => forgotPasswordRedirection()}
                      >
                        Forgotten Password?
                      </h6>
                    </div>

                    <div className="sign-up-btn">
                      <a>
                        <button type="submit" className="signning-btn">
                          Log In
                        </button>
                      </a>
                      <h6 className="already-have-headings">
                        Don't have a&nbsp;
                        <label
                          className="signUpHeading"
                          style={{ color: "#fd6a02" }}
                        >
                          Q
                        </label>
                        Skipper account?
                        <label
                          className="signUpHeading"
                          style={{ color: "#fd6a02", cursor: "pointer" }}
                          onClick={() => {
                            setIsLoginVisible(false);
                            resetData();
                            addParamsInUrl(2);
                          }}
                        >
                          &nbsp;Sign Up
                        </label>
                      </h6>
                    </div>
                  </form>
                </div>
              )}

              {!isLoginVisible && (
                <div
                  className="tab-pane fade show active"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-active-tab"
                >
                  <div className="UploadImage">
                    <div className="userImage">
                      <input
                        id="file"
                        type="file"
                        accept="JPG, png, svg "
                        onChange={(event) => {
                          handleInput(event);
                        }}
                      />
                      <img
                        className="upload-image"
                        src={
                          locationImg
                            ? URL.createObjectURL(selectedImage[0])
                            : UserIcon
                        }
                        alt=""
                      />

                      <div className="AddImage">
                        <img className="add-image" src={UserAddimage} alt="" />
                      </div>
                    </div>
                  </div>

                  <form
                    method="dialog"
                    onSubmit={(e:any)=>signupAPICall(e)}
                    autoComplete="off"
                  >
                    <div className="form-group">
                      <label htmlFor="exampleInputFull name1">Full name </label>
                      <input
                        maxLength={25}
                        type="text"
                        className="form-control"
                        id="exampleInputFull name1"
                        value={nameSignup}
                        onChange={(e) =>
                          setNameSignup(
                            e.target.value.startsWith(" ")
                              ? e.target.value.replace(/\ /, "")
                              : e.target.value
                          )
                        }
                        placeholder="Enter your full name"
                        autoComplete="off"
                      />
                      {isSubmit && nameSignup.length == 0 ? (
                        <div style={{ color: "red" }}>
                          Please enter your full name.
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleFormControlSelect1">Country</label>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        value={selectedCountry}
                        onChange={(e) => {
                          let selectedObj: any = countryList.filter(
                            (x: any) => e.target.value === x?.value
                          )[0];
                          setSelectedCountry(selectedObj.value);
                        }}
                        placeholder="Select a country"
                        autoComplete="off"
                      >
                        <option value={""} disabled>
                          Select a country
                        </option>
                        {countryList.map((item: any, index: number) => {
                          return (
                            <option key={index} value={item?.value}>
                              {item?.label}
                            </option>
                          );
                        })}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-down"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                      {isSubmit && selectedCountry.length == 0 ? (
                        <div style={{ color: "red" }}>
                          Please select your country.
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPhone Number1">
                        Phone Number
                      </label>
                      <input
                        maxLength={11}
                        type="text"
                        className="form-control"
                        id="exampleInputPhone Number1"
                        value={mobileSignup}
                        onChange={(e) => setMobileSignup(e.target.value)}
                        placeholder="Enter mobile number"
                        onKeyPress={(event) => phoneNumberCheck(event)}
                        autoComplete="off"
                      />
                      {isSubmit && mobileSignup.length == 0 ? (
                        <div style={{ color: "red" }}>
                          Please enter your phone number.
                        </div>
                      ) : (
                        <></>
                      )}
                      {(isSubmit &&
                        mobileSignup.length > 0 &&
                        mobileSignup.length < 10) ||
                      (mobileSignup.length > 0 &&
                        isNaN(parseInt(mobileSignup))) ? (
                        <div style={{ color: "red" }}>
                          Please enter a valid phone number.
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Email address</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        value={emailSignup}
                        onChange={(e) => setEmailSignup(e.target.value)}
                        placeholder="Your email address"
                        autoComplete="new-email"
                      />
                      {isSubmit && emailSignup.length == 0 ? (
                        <div style={{ color: "red" }}>
                          Please enter your email address.
                        </div>
                      ) : (
                        <></>
                      )}
                      {isSubmit &&
                      emailSignup.length > 0 &&
                      !validateEmail(emailSignup) ? (
                        <div style={{ color: "red" }}>
                          Please enter a valid email.
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Password</label>
                      <div className="password_div">
                        <input
                          maxLength={32}
                          type={!isShowPassword ? "password" : "text"}
                          className="form-control"
                          id="exampleInputPassword1"
                          value={passwordSignup}
                          onKeyPress={(event) => spaceCheckForSignUp(event)}
                          onChange={(e) => setPasswordSignup(e.target.value)}
                          placeholder="*********"
                          autoComplete="new-password"
                        />
                        <span className="password_eye">
                          {!isShowPassword ? (
                            <i
                              className="fa fa-eye-slash"
                              onClick={() => passwordShowHandler(true)}
                            ></i>
                          ) : (
                            <i
                              className="fa fa-eye"
                              onClick={() => passwordShowHandler(false)}
                            ></i>
                          )}
                        </span>
                      </div>
                      {isSubmit && passwordSignup.length == 0 ? (
                        <div style={{ color: "red" }}>
                          Please enter password.
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="sign-up-btn">
                      <button type="submit" className="signning-btn">
                        Sign Up
                      </button>
                      <h6 className="already-have-heading">
                        By continuing Sign Up you agree to the following
                      </h6>
                      <h6
                        onClick={() => window.open(TERMS_AND_CONDITIONS)}
                        className="terms-condition"
                      >
                        Terms & Condition
                      </h6>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {loading ? <Spinner></Spinner> : <></>}
    </div>
  );
};
export default Login;
