declare global {
  interface Window {
    gapi: any;
  }
}

export default function CalendarApi(
  email?: any,
  startDate?: any,
  endDate?: any,
  title?: any
) {
  let gapi = window.gapi;
  let CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  let SCOPES = "https://www.googleapis.com/auth/calendar";

  gapi.load("client:auth2", () => {
    gapi.client.init({
      scope: SCOPES,
      clientId: CLIENT_ID,
      plugin_name: "QSkipper",
    });
    gapi.client.load("calendar", "v3", () => console.log(""));
    gapi.auth2
      .getAuthInstance()
      .signIn()
      .then(() => {
        var event = {
          summary: title,
          location: "",
          description: `Why not download the app and unlock all the features?
          - Manage your bookings
          - Booking reminders
          - Cancel or reschedule your appointments
          - Payment history
          - And more!
          Download now: 
          - Android: https://play.google.com/store/apps/details?id=app.qskipper&hl=en_IN&gl=US
          - IOS: https://apps.apple.com/us/app/qskipper/id1566103523`,
          start: {
            dateTime: startDate,
            timeZone: "America/Los_Angeles",
          },
          end: {
            dateTime: endDate,
            timeZone: "America/Los_Angeles",
          },
          recurrence: ["RRULE:FREQ=DAILY;COUNT=2"],
          attendees: [{ email: `${email}` }],
          reminders: {
            useDefault: false,
            overrides: [
              { method: "popup", minutes: 24 * 60 },
              { method: "popup", minutes: 6 * 60 },
              { method: "popup", minutes: 1 * 60 },
            ],
          },
        };

        var request = gapi.client.calendar.events.insert({
          calendarId: "primary",
          resource: event,
        });

        request.execute((event: any) => {
          window.open(event.htmlLink);
        });
      })
      .catch((err: any) => console.log("error in that!", err));
  });
}
