import { Navigate } from "react-router-dom";

export interface PublicRouteProps {
  redirectPath: string;
  children: any;
}

export default function PublicRoute(props: PublicRouteProps) {
  const { redirectPath, children } = props;

  const isVerified = sessionStorage.getItem("AUTHTOKEN_USER") ? true : false;

  if (isVerified) {
    if (sessionStorage.getItem("AUTHTOKEN_USER")) {
      sessionStorage.removeItem("AUTHTOKEN_USER");
    }
    if (sessionStorage.getItem("USER_ID")) {
      sessionStorage.removeItem("USER_ID");
    }
    return <Navigate to={redirectPath} replace />;
  }

  return children;
}
