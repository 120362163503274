import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RESEND_OTP, SEND_OTP } from "../../services/EndPoints";
import { HttpCallPost } from "../../services/NetworkCall";
import Spinner from "../../Shared/Spinner/Spinner";
import { ToastOnFailure, ToastOnSuccess } from "../../Shared/Toast/Toast";
import "./VerifyOTP.style.scss";

const VerifyOTP = ({}) => {
  let navigate = useNavigate();

  const [otp, setOTP] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);

  const location: any = useLocation();
  const [loading, setLoading] = useState(false);
  const resendAPICall = () => {
    setLoading(true);
    let data = {
      phoneNumber: location?.state?.customerForgotPassMobileNumber,
    };
    HttpCallPost(RESEND_OTP, data)
      .then((response: any) => {
        setLoading(false);
        if (response.data.status == 200) {
          setOTP("");
          ToastOnSuccess(response.data.msg);
        } else if (response.data.status == 400) {
          ToastOnFailure(response.data.msg);
        } else {
          ToastOnFailure(response.data.msg);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const otpAPICall = (e: any) => {
    e.preventDefault();
    setIsSubmit(true);
    if (otp && otp.length === 4 && !isNaN(parseInt(otp))) {
      setLoading(true);
      let data = {
        id: location?.state?.customerForgotPassID,
        tempOtp: otp,
      };
      HttpCallPost(SEND_OTP, data)
        .then((response: any) => {
          setLoading(false);
          if (response.data.status == 200) {
            setOTP("");
            ToastOnSuccess(response.data.msg);
            navigate(location?.state?.reDirectionUrl, {
              state: {
                customerForgotPassMobileNumber:
                  location?.state?.customerForgotPassMobileNumber,
                customerForgotPassID: location?.state?.customerForgotPassID,
              },
              replace: true,
            });
          } else if (response.data.status == 400) {
            ToastOnFailure(response.data.msg);
          } else {
            ToastOnFailure(response.data.msg);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const otpCheck = (evt: any) => {
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return true && evt.preventDefault();

    return false && evt.preventDefault();
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <form method="dialog" onSubmit={(e: any) => otpAPICall(e)}>
            <div className="Verify-OTP-section">
              <div className="OTP-Number">
                {location?.state?.reDirectionUrl.includes("login") ? (
                  <h4 className="enter-OTP-heading">
                    Verify your mobile number&nbsp;
                    {location?.state?.customerForgotPassMobileNumber} <br />
                  </h4>
                ) : (
                  <h4 className="enter-OTP-heading">
                    Verify your mobile number&nbsp;
                    {location?.state?.customerForgotPassMobileNumber} <br />
                    to set a new password
                  </h4>
                )}
              </div>
              <div className="OTP-number-feild">
                <div className="form-group">
                  <label htmlFor="exampleInputPhone Number1">Enter OTP </label>
                  <input
                    type="text"
                    className="form-control number3"
                    id="exampleInputEnter OTP1"
                    maxLength={4}
                    value={otp}
                    placeholder="Enter one time password (OTP)"
                    onKeyPress={(event) => otpCheck(event)}
                    onChange={(e: any) => setOTP(e.target.value)}
                  />
                  {isSubmit && otp.length == 0 ? (
                    <div style={{ color: "red" }}>Please enter otp.</div>
                  ) : (
                    <></>
                  )}

                  {(isSubmit && otp.length > 0 && otp.length < 4) ||
                  (otp.length > 0 && isNaN(parseInt(otp))) ? (
                    <div style={{ color: "red" }}>Please enter valid otp.</div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="Confirm-btn-section">
                  <h6 className="didnt Get-OTP">
                    Didn't get the OTP? <br />
                    <label onClick={() => resendAPICall()} className="resend">
                      Resend
                    </label>
                  </h6>
                  <a>
                    <button className="signning-btn" type="submit">
                      Confirm
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading ? <Spinner></Spinner> : <></>}
    </div>
  );
};
export default VerifyOTP;
