export const icInstagram: string = require("../assets/instagram_icon.png");
export const icEmail: string = require("../assets/message_icon.png");
export const qSkipperLogoWhite: string = require("../assets/FooterIcons/qskipper-white-logo.png");
export const qSkipperLogoBlack: string = require("../assets/qskipper Black logo.png");
export const icFacebook: string = require("../assets/facebook_icon.png");
export const icLocation: string = require("../assets/location_icon.png");
export const icTiktok: string = require("../assets/tictok_icon.png");
export const icBarberProfile: string = require("../assets/barberProfile.png");
export const icStar: string = require("../assets/star.png");

export const icBarberCut: string = require("../assets/hair-cutting-image.png");
export const icWebsite: string = require("../assets/website_icon.png");
export const icPhone: string = require("../assets/mobile_icon.png");
export const icTiktokOrange: string = require("../assets/tiktok_orange_icon.png");
export const icLocationOrange: string = require("../assets/location_orange_icon.png");
export const icInstagramBlack: string = require("../assets/instagram_black_icon.png");
export const icClock: string = require("../assets/NewClock.png");

export const icRatingProgress1: string = require("../assets/progressbar-icon1.png");
export const icRatingProgress2: string = require("../assets/progressbar-icon2.png");
export const icRatingProgress4: string = require("../assets/progressbar-icon4.png");
export const icRatingProgress5: string = require("../assets/progressbar-icon5.png");
export const icGreyTick: string = require("../assets/gray-tick.png");
export const icOrangeTick: string = require("../assets/orange-tick.png");
