import { useState } from "react";
import { Alert } from "react-bootstrap";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Login from "../pages/login/Login";
import VerifyOTP from "../pages/verifyOTP/VerifyOTP";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import ShopDetails from "../pages/shopDetails/ShopDetails";
import ForgetPassword from "../components/ForgetPassword/ForgetPassword";
import ResetPassword from "../pages/resetPassword/ResetPassword";
import Payment from "../components/Payment/Payment";
import Calender from "../components/Calender/Calender";
import BarbarList from "../pages/barbarList/BarbarList";
import PublicRoute from "./PublicRoute";

export default function AppRoute() {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      {isLoading ? (
        <Alert variant="primary" className="m-5">
          Loading...
        </Alert>
      ) : (
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Navigate to="/barbers" />} />
            <Route
              path="login"
              element={
                <PublicRoute redirectPath="/barbers">
                  <Login />
                </PublicRoute>
              }
            />
            <Route path="payment" element={<Payment />} />
            {/* <Route path="calender" element={<Calender />} /> */}
            <Route
              path="forget"
              element={
                <PublicRoute redirectPath="/barbers">
                  <ForgetPassword />
                </PublicRoute>
              }
            />
            <Route
              path="verify"
              element={
                <PublicRoute redirectPath="/barbers">
                  <VerifyOTP />
                </PublicRoute>
              }
            />
            <Route
              path="reset-password"
              element={
                <PublicRoute redirectPath="/barbers">
                  <ResetPassword />
                </PublicRoute>
              }
            />
            <Route path="barber/:id" element={<ShopDetails />} />
            <Route path="barbers" element={<BarbarList />} />
            <Route path="*" element={<Navigate to="/barbers" />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      )}
    </>
  );
}
