import { useEffect, useRef, useState } from "react";
import { BARBER_LIST } from "../../services/EndPoints";
import { HttpCallGet } from "../../services/NetworkCall";
import Spinner from "../../Shared/Spinner/Spinner";
import BarbarCard from "./barbarCard/BarbarCard";
import "./BarbarList.style.scss";
import { useSearchParams } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import NewLocationIcon from "../../assets/NewLocationIon.png";
import onHoverBox from "../../assets/onHoverBox.png";
import SearchLocationInput from "../../Shared/SearchLocation/SearchLocationInput";

const BarbarList = ({}) => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(9);
  const [searchText, setSearchText] = useState(
    searchParams.get("search") || ""
  );
  const [searchLocationText, setSearchLocationText] = useState("");
  const [barberList, setBarberList] = useState([]);
  const [barberCount, setBarberCount] = useState(0);
  const searchRef = useRef<any>();
  const locationRef = useRef<any>();
  const [nameFieldFocused, setNameFieldFocused] = useState(false);
  const [locationFieldFocused, setLocationFieldFocused] = useState(false);
  const [locationObj, setLocationObj] = useState<any>({});
  const [locationLat, setLocationLat] = useState<any>(
    searchParams.get("lat") || null
  );
  const [locationLng, setLocationLng] = useState<any>(
    searchParams.get("lng") || null
  );

  const [searchByCurrentLocationFlag, setSearchByCurrentLocationFlag] =
    useState<any>(false);

  const fetchBarberList = () => {
    if (searchText.length > 0 || (locationLat != null && locationLng != null)) {
      setLoading(true);
      let urlPoint = `${BARBER_LIST}/?page=${page}&limit=${limit}`;
      if (searchText.length > 0 && locationLat != null && locationLng != null) {
        urlPoint =
          urlPoint +
          `&search=${searchText}&lat=${locationLat}&lng=${locationLng}`;
      } else if (searchText.length > 0) {
        urlPoint = urlPoint + `&search=${searchText}`;
      } else if (locationLat != null && locationLng != null) {
        urlPoint = urlPoint + `&lat=${locationLat}&lng=${locationLng}`;
      }

      HttpCallGet(urlPoint)
        .then((response: any) => {
          if (response.data.status == 200 || response.data.status == 201) {
            setLoading(false);
            setBarberCount(response.data.totalCount);
            const barbers: any = [...barberList, ...response.data.data];
            setBarberList(barbers);
          } else {
            setLoading(false);
            setBarberCount(0);
            setBarberList([]);
          }
        })
        .catch((err: any) => {
          console.log(err.message);
          setLoading(false);
        });
    } else {
      setBarberList([]);
      setBarberCount(0);
    }
  };

  useEffect(() => {
    fetchBarberList();
  }, [page, limit, searchText, locationLat, locationLng]);

  useEffect(() => {
    searchRef.current.value = searchParams.get("search")
      ? searchParams.get("search")
      : "";
    locationRef.current.value = searchParams.get("address")
      ? searchParams.get("address")
      : "";
  }, [searchParams.get("search"), searchParams.get("address")]);

  const fetchLoadMore = () => {
    setPage(page + 1);
  };

  const currentLocationHandler = () => {
    if (!searchByCurrentLocationFlag) {
      searchRef.current.value = "";
      locationRef.current.value = "";
      setBarberList([]);
      setPage(1);
      setSearchLocationText("");
      setSearchText("");
      navigator.geolocation.getCurrentPosition(
        (success) => {
          console.log(success);
          setLocationLat(success?.coords?.latitude);
          setLocationLng(success?.coords?.longitude);
          setSearchByCurrentLocationFlag(true);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const searchHandler = (e: any) => {
    e.preventDefault();
    if (
      searchRef.current.value !== searchText ||
      locationRef.current.value !== searchLocationText
    ) {
      setSearchByCurrentLocationFlag(false);
      setBarberList([]);
      setPage(1);
      setSearchText(searchRef.current.value);
      setSearchLocationText(locationRef.current.value);
      if (locationRef.current.value && Object.keys(locationObj).length > 0) {
        setLocationLat(locationObj?.geometry?.location?.lat());
        setLocationLng(locationObj?.geometry?.location?.lng());
      } else {
        setLocationLat(null);
        setLocationLng(null);
      }
    }
  };

  const spaceCheckForSearch = (evt: any) => {
    if (evt.which === 32 && !searchRef.current.value.length)
      evt.preventDefault();
  };

  const showDistance = () => {
    return (
      Object.values(locationObj).length > 0 ||
      (Object.keys(locationObj).length > 0 &&
        searchRef?.current?.value?.length > 0) ||
      (locationLat !== null && locationLng !== null)
    );
  };

  return (
    <>
      <div className="searchBarbar-Section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <form method="dialog" onSubmit={(e: any) => searchHandler(e)}>
                <div className="searchBarbar">
                  <div className="searchBar1">
                    <div className="form-group">
                      <input
                        type="text"
                        ref={searchRef}
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter barber's ID or name"
                        aria-describedby="emailHelp"
                        onFocus={() => setNameFieldFocused(true)}
                        onBlur={() => setNameFieldFocused(false)}
                        onKeyPress={(event) => spaceCheckForSearch(event)}
                      />
                      <div className="SearchIcon mobile-view">
                        <i
                          className="fa fa-search"
                          aria-hidden="true"
                          style={{ color: nameFieldFocused ? "#f47321" : "" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="searchBar">
                    <p className="OrHeading">Or</p>
                  </div>
                  <div className="searchBar2">
                    <div className="form-group">
                      <div className="BarberSearch-Sec">
                        <Autocomplete
                          style={{ display: "none" }}
                          className="search-input"
                          ref={locationRef}
                          placeholder="Search by location"
                          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                          onPlaceSelected={(place: any) => {
                            setLocationObj(place);
                          }}
                          onFocus={() => setLocationFieldFocused(true)}
                          onBlur={() => setLocationFieldFocused(false)}
                        />
                        <SearchLocationInput
                          locationRef={locationRef}
                          setLocationObj={setLocationObj}
                        />
                        <div
                          className="barberSearch-icon"
                          onClick={() => currentLocationHandler()}
                        >
                          <img
                            className="barberLocationIcon"
                            src={NewLocationIcon}
                            alt=""
                          />
                        </div>
                        <div className="onhover">
                          <div
                            className="box"
                            style={{
                              color: locationFieldFocused ? "#f47321" : "",
                            }}
                          >
                            Search by current location
                          </div>
                          {/* 

                        </div>
                        <div className="SearchIcon mobile-view">
                          {/* <i
                            className="fa fa-search"
                            aria-hidden="true"
                            style={{
                              color: locationFieldFocused ? "#f47321" : "",
                            }}
                          ></i> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div
                    className="currentLocationButton"
                    onClick={() => currentLocationHandler()}
                  >
                    <span>
                      <svg
                        width="18"
                        height="25"
                        viewBox="0 0 18 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 21C14.3333 16.3333 17 11.8889 17 7.66667C17 5.89856 16.1571 4.20286 14.6569 2.95262C13.1566 1.70238 11.1217 1 9 1C6.87827 1 4.84344 1.70238 3.34315 2.95262C1.84286 4.20286 1 5.89856 1 7.66667C1 11.8889 3.66667 16.3333 9 21Z"
                          stroke="#F47321"
                          stroke-miterlimit="8"
                          stroke-linecap="square"
                        />
                        <path
                          d="M9 11C10.1046 11 11 10.1046 11 9C11 7.89543 10.1046 7 9 7C7.89543 7 7 7.89543 7 9C7 10.1046 7.89543 11 9 11Z"
                          stroke="#FD6A02"
                          stroke-miterlimit="8"
                          stroke-linecap="square"
                        />
                      </svg>
                    </span>
                  </div> */}
                  <div className="searchBar">
                    <button type="submit" className="searchBtn">
                      Search
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="SearchResult">
                <h2 className="searchResultHeading">
                  Search results
                  {searchByCurrentLocationFlag ? " by current location" : ""}
                  .....
                </h2>
                <h4 className="foundHeading">{barberCount} barber(s) found</h4>
                <h5 className="NearHeading">Barber shop near you</h5>
              </div>
            </div>
          </div>
          <div className="row">
            {barberList &&
              barberList.length > 0 &&
              barberList.map((barBer, index) => (
                <BarbarCard
                  key={index}
                  data={barBer}
                  index={index}
                  showDistance={showDistance}
                />
              ))}
          </div>
          {barberCount > barberList.length && (
            <div
              className="row read-more text-end mt-4"
              onClick={() => fetchLoadMore()}
            >
              <p>Load More</p>
            </div>
          )}
        </div>
      </div>
      {loading ? <Spinner></Spinner> : <></>}
    </>
  );
};

export default BarbarList;
