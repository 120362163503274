import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RESET_PASSWORD } from "../../services/EndPoints";
import { HttpCallPost } from "../../services/NetworkCall";
import Spinner from "../../Shared/Spinner/Spinner";
import { ToastOnFailure, ToastOnSuccess } from "../../Shared/Toast/Toast";
import "./ResetPassword.style.scss";

const ResetPassword = ({}) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);

  const location: any = useLocation();
  let navigate = useNavigate();

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const resetAPICall = (e:any) => {
    e.preventDefault();
    setIsSubmit(true);
    setNewPassword(newPassword.trim());
    setConfirmPassword(confirmPassword.trim());
    if (newPassword && confirmPassword && newPassword === confirmPassword) {
      setLoading(true);
      let data = {
        phoneNumber: location?.state?.customerForgotPassMobileNumber,
        password: newPassword,
        confirmPassword: confirmPassword,
      };
      HttpCallPost(RESET_PASSWORD, data)
        .then((response: any) => {
          setLoading(false);
          if (response.data.status == 200) {
            ToastOnSuccess(response.data.msg);
            navigate("/login?tab=1", {
              replace: true,
            });
          } else if (response.data.status == 400) {
            ToastOnFailure(response.data.msg);
          } else {
            ToastOnFailure(response.data.msg);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const passwordShowHandler = (flag: boolean) => {
    setIsShowPassword(flag);
  };

  const confirmPasswordShowHandler = (flag: boolean) => {
    setIsShowConfirmPassword(flag);
  };

  const spaceCheckForNewPassword = (evt: any) => {
    if (evt.which === 32 && !newPassword.length) evt.preventDefault();
  };

  const spaceCheckForCurrentPassword = (evt: any) => {
    if (evt.which === 32 && !confirmPassword.length) evt.preventDefault();
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <form method="dialog" onSubmit={(e:any)=>resetAPICall(e)}>
            <div className="New-Password-section">
              <div className="New-password">
                <h4 className="New-password-heading">
                  Set new password <br />
                  for account linked to number&nbsp;
                  {location?.state?.customerForgotPassMobileNumber}
                </h4>
              </div>
              <div className="Set-new-password">
                <div className="form-group">
                  <label htmlFor="exampleInputPhone Number4">
                    New Password
                  </label>
                  <div className="password_div">
                    <input
                      maxLength={32}
                      type={!isShowPassword ? "password" : "text"}
                      className="form-control number4"
                      id="exampleInputEnter OTP1"
                      value={newPassword}
                      placeholder="Enter new password"
                      onKeyPress={(event) => spaceCheckForNewPassword(event)}
                      onChange={(e: any) => setNewPassword(e.target.value)}
                    />
                    <span className="password_eye">
                      {!isShowPassword ? (
                        <i
                          className="fa fa-eye-slash"
                          onClick={() => passwordShowHandler(true)}
                        ></i>
                      ) : (
                        <i
                          className="fa fa-eye"
                          onClick={() => passwordShowHandler(false)}
                        ></i>
                      )}
                    </span>
                  </div>

                  {isSubmit && newPassword.length == 0 ? (
                    <div style={{ color: "red" }}>Please enter password.</div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPhone Number5">
                    Confirm Password
                  </label>
                  <div className="password_div">
                    <input
                      maxLength={32}
                      type={!isShowConfirmPassword ? "password" : "text"}
                      className="form-control number5"
                      id="exampleInputEnter OTP1"
                      value={confirmPassword}
                      placeholder="Re-enter new password"
                      onKeyPress={(event) =>
                        spaceCheckForCurrentPassword(event)
                      }
                      onChange={(e: any) => setConfirmPassword(e.target.value)}
                    />
                    <span className="password_eye">
                      {!isShowConfirmPassword ? (
                        <i
                          className="fa fa-eye-slash"
                          onClick={() => confirmPasswordShowHandler(true)}
                        ></i>
                      ) : (
                        <i
                          className="fa fa-eye"
                          onClick={() => confirmPasswordShowHandler(false)}
                        ></i>
                      )}
                    </span>
                  </div>

                  {isSubmit && confirmPassword.length == 0 ? (
                    <div style={{ color: "red" }}>
                      Please enter confirm password.
                    </div>
                  ) : (
                    <></>
                  )}
                  {isSubmit &&
                  newPassword.length > 0 &&
                  confirmPassword.length > 0 &&
                  newPassword !== confirmPassword ? (
                    <div style={{ color: "red" }}>
                      Both passwords need to match.
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="Save-btn-section">
                  <a>
                    <button type="submit" className="signning-btn">
                      Save
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading ? <Spinner></Spinner> : <></>}
    </div>
  );
};
export default ResetPassword;
