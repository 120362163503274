import { useEffect, useState } from "react";
import "./payment.style.scss";
import { StripePaymentForm } from "./StripePaymentForm";
import DeleteImg from "../../assets/delete.svg";
import { HttpCallGet, HttpCallPost } from "../../services/NetworkCall";

import { useLocation, useNavigate } from "react-router-dom";

import {
  ADD_GET_CARD_STRIPE,
  BARBER_COLLEAGE_LIST,
  CUSTOMER_ADVANCE_STRIPE_PAYMENT,
  CUSTOMER_GET_PROFILE_DETAILS,
  DELETE_CARD_STRIPE,
} from "../../services/EndPoints";
import { ToastOnFailure, ToastOnSuccess } from "../../Shared/Toast/Toast";
import moment from "moment";
import { Modal } from "react-bootstrap";
import PlusImage from "../../assets/plus02icon.png";
import Spinner from "../../Shared/Spinner/Spinner";
import CalendarApi from "../../services/AppointmentCalender";

interface Props {
  data?: any;
}

const Payment = ({ data }: Props) => {
  const location: any = useLocation();
  const navigate = useNavigate();
  const token = sessionStorage.getItem("AUTHTOKEN_USER");
  const [cardDetails, setCardDetails] = useState([]);
  const [userEmail, setUserEmail] = useState("");

  const [cardName, setCardName] = useState("");
  const [show, setShow] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [selectedCardDetails, setSelectedCardDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [blockLoadMore, setBlockLoadMore] = useState(false);

  const paymentData = sessionStorage.getItem("paymentData")
    ? JSON.parse(sessionStorage.getItem("paymentData") || "{}")
    : location?.state;

  const totalServiceAmount = paymentData?.serviceSelected.reduce(
    (accumulator: any, object: any) => {
      return accumulator + object.price;
    },
    0
  );

  const advancePaymentPayable =
    totalServiceAmount < Number(paymentData?.advancePaymentAmount)
      ? totalServiceAmount
      : Number(paymentData?.advancePaymentAmount);

  const totalBookingFee =
    advancePaymentPayable +
    paymentData?.data?.bookingCharges +
    paymentData?.data?.transactionFee +
    (advancePaymentPayable + paymentData?.data?.bookingCharges) *
      (paymentData?.data?.percentage / 100) -
    advancePaymentPayable;

  const totalAmount = totalServiceAmount + totalBookingFee;

  let providerId = "";
  let detailBarber: any = {};

  if (sessionStorage.getItem("barberDetails") != null) {
    detailBarber = JSON.parse(sessionStorage.getItem("barberDetails") || "{}");
    providerId = detailBarber?.colleagueId;
  }

  const handleClose = () => {
    setShow(false);
    navigate(`/barber/${sessionStorage.getItem("colleagueId")}`);
  };

  const handleBlockModalClose = () => {
    setShowBlockModal(false);
  };

  const getCustomerProfileDetails = () => {
    HttpCallGet(CUSTOMER_GET_PROFILE_DETAILS, token)
      .then((response: any) => {
        setUserEmail(response?.data?.data?.email);
      })
      .catch((err: any) => {
        console.log(err.message);
      });
  };

  /// get Existing card Details
  const getExistingCardDetails = () => {
    HttpCallPost(ADD_GET_CARD_STRIPE, {}, token)
      .then((response: any) => {
        if (response?.data?.status === 200) {
          setCardDetails(response.data.data);
          setSelectedCardDetails(
            response.data.data.length > 0 ? response.data.data[0].id : ""
          );
        }
        if (response?.data?.status === 400) {
          ToastOnFailure(response.data.msg);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status == 403) {
          ToastOnFailure("Please login");
        }
        if (error?.response?.status == 401) {
          ToastOnFailure("Please login again session expired");
        }
        sessionStorage.setItem("previousUrl", paymentData?.reDirectionUrl);
        sessionStorage.setItem("paymentData", JSON.stringify(location?.state));

        if (sessionStorage.getItem("AUTHTOKEN_USER")) {
          sessionStorage.removeItem("AUTHTOKEN_USER");
        }
        if (sessionStorage.getItem("USER_ID")) {
          sessionStorage.removeItem("USER_ID");
        }
        navigate("/login?tab=1", { replace: true });
      });
  };

  // delete existing card details

  const deleteExistingCardDetails = (cardId: any) => {
    const promptCnf = window.confirm("Do you  you want to Remove the Card?");
    if (promptCnf) {
      HttpCallPost(DELETE_CARD_STRIPE, { cardId: cardId }, token)
        .then((response: any) => {
          if (response?.data?.status === 200) {
            ToastOnSuccess("Card deleted successfully");
            getExistingCardDetails();
            setSelectedCardDetails("");
          }
          if (response?.data?.status === 400) {
            ToastOnFailure(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.status == 403) {
            ToastOnFailure("Please login");
          }
          if (error?.response?.status == 401) {
            ToastOnFailure("Please login again session expired");
          }
          sessionStorage.setItem("previousUrl", paymentData?.reDirectionUrl);
          sessionStorage.setItem(
            "paymentData",
            JSON.stringify(location?.state)
          );

          if (sessionStorage.getItem("AUTHTOKEN_USER")) {
            sessionStorage.removeItem("AUTHTOKEN_USER");
          }
          if (sessionStorage.getItem("USER_ID")) {
            sessionStorage.removeItem("USER_ID");
          }
          navigate("/login?tab=1", { replace: true });
        });
    }
  };

  useEffect(() => {
    getExistingCardDetails();
    if (providerId) {
      FetchBarberList(providerId);
    }
  }, []);

  /// Booking api would be called here

  const BookApointment = () => {
    let dataValue = {
      colleagueId: paymentData?.data?.id,
      time: paymentData?.getTime,
      date: Number(moment(paymentData?.newDate).format("x")),
      serviceIds: paymentData?.serviceSelected.map((item: any) => item.id),
      bookingCharge: parseFloat(totalBookingFee.toString()).toFixed(2),
      duration: paymentData?.time,
      customerComment: paymentData?.comment,
      advancePayment: advancePaymentPayable,
      toPayAtBarbers: parseFloat(
        (
          totalAmount -
          parseFloat(
            (
              parseFloat(advancePaymentPayable.toString()) +
              parseFloat(totalBookingFee.toString())
            ).toString()
          )
        )
          .toFixed(2)
          .toString()
      ),
      isAdvancePayment: true,
      currency: "inr",
      amount: parseFloat(totalAmount.toString()).toFixed(2),
      cardId: selectedCardDetails ? selectedCardDetails : [],
      paymentType: "Advance Payment",
    };
    setLoading(true);
    HttpCallPost(CUSTOMER_ADVANCE_STRIPE_PAYMENT, dataValue, token)
      .then((response: any) => {
        setLoading(false);
        if (response?.data?.status === 200) {
          getCustomerProfileDetails();
          setShow(true);
        }
        if (response?.data?.status === 400) {
          ToastOnFailure(response.data.msg);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error?.response?.status == 403) {
          ToastOnFailure("Please login");
        }
        if (error?.response?.status == 401) {
          ToastOnFailure("Please login again session expired");
        }
        sessionStorage.setItem("previousUrl", paymentData?.reDirectionUrl);
        sessionStorage.setItem("paymentData", JSON.stringify(location?.state));

        if (sessionStorage.getItem("AUTHTOKEN_USER")) {
          sessionStorage.removeItem("AUTHTOKEN_USER");
        }
        if (sessionStorage.getItem("USER_ID")) {
          sessionStorage.removeItem("USER_ID");
        }
        navigate("/login?tab=1", { replace: true });
      });
  };

  const addToCalander = () => {
    const calanderTitle = paymentData?.serviceSelected
      .map((res: any) => {
        return res.title.trim();
      })
      .join(", ");

    const startDate = new Date(
      moment(paymentData?.newDate).format("YYYY-MM-DD") +
        " " +
        paymentData?.getTime
    );
    const endDate = new Date(
      moment(
        new Date(
          moment(paymentData?.newDate).format("YYYY-MM-DD") +
            " " +
            paymentData?.getTime
        )
      )
        .add(paymentData?.time, "m")
        .format()
    );

    CalendarApi(userEmail, startDate, endDate, calanderTitle);
  };

  const clearCardDetails = () => {
    setCardName("");
  };

  const chooseAnotherBarberHandler = () => {
    navigate("/barbers");
  };

  const FetchBarberList = (id: any) => {
    HttpCallGet(
      `${BARBER_COLLEAGE_LIST}/${id}?page=1&limit=50`,
      sessionStorage.getItem("AUTHTOKEN_USER")
    )
      .then((response: any) => {
        detailBarber.isBlock = response?.data?.data?.isBlock;
        sessionStorage.setItem("barberDetails", JSON.stringify(detailBarber));

        if (
          paymentData.data.id === detailBarber.colleagueId &&
          detailBarber.isBlock
        ) {
          setShowBlockModal(true);
        } else {
          const index = response?.data?.data?.colleagueDetails.findIndex(
            (e: any) => {
              return e.colleagueId === paymentData.data.id;
            }
          );

          if (index > -1) {
            if (response?.data?.data?.colleagueDetails[index].isBlock) {
              setShowBlockModal(true);
            }
          }
        }
      })
      .catch((err: any) => {
        console.log(err.message);
      });
  };

  return (
    <>
      <div className="newCard">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="mainbox">
                <div className="advanceSection">
                  <div className="CuttingStyle">
                    <div className="hairCutting">
                      {paymentData?.serviceSelected
                        ? paymentData?.serviceSelected.map((item: any) => {
                            return (
                              <div className="hairSection" key={item.id}>
                                <h5 className="hairHeading">{item.title}</h5>
                                <h5 className="hairHeading PriceSec">
                                  {item.countery.currencySymbol}
                                  {item.price.toFixed(2)}
                                </h5>
                              </div>
                            );
                          })
                        : null}

                      <div className="hairSection">
                        <h5 className="hairHeading">Booking Fees</h5>
                        <h5 className="hairHeading PriceSec">
                          {sessionStorage.getItem("currencySymbol")}
                          {parseFloat(totalBookingFee.toString()).toFixed(2)}
                        </h5>
                      </div>

                      <hr />
                    </div>
                    <div className="advancePaymentSection">
                      <div className="hairSection">
                        <h5 className="hairHeading">Total</h5>
                        <h5 className="hairHeading PriceSec">
                          {sessionStorage.getItem("currencySymbol")}
                          {parseFloat(totalAmount.toString()).toFixed(2)}
                        </h5>
                      </div>
                      <div className="hairSection">
                        <h5 className="hairHeading">Advance Payment</h5>
                        <h5 className="hairHeading PriceSec">
                          {sessionStorage.getItem("currencySymbol")}
                          {advancePaymentPayable.toFixed(2)}
                        </h5>
                      </div>
                      <div className="hairSection">
                        <h5 className="hairHeading bold">To pay at Barbers</h5>
                        <h5 className="totalPrice PriceSec">
                          {sessionStorage.getItem("currencySymbol")}
                          {(
                            totalAmount -
                            parseFloat(
                              (
                                parseFloat(advancePaymentPayable.toString()) +
                                parseFloat(totalBookingFee.toString())
                              ).toString()
                            )
                          ).toFixed(2)}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vesaBox">
                  {cardDetails ? (
                    <h4 className="SelectPaymentHeading">
                      Select a payment method
                    </h4>
                  ) : (
                    <h4 className="SelectPaymentHeading">Please Add Card</h4>
                  )}

                  <fieldset className="form-group row VisaFieldSection">
                    <div className="VisaField">
                      {cardDetails ? (
                        cardDetails.map((item: any) => {
                          const { id, exp_month, exp_year, brand, last4 } =
                            item;
                          return (
                            <div
                              className="form-check"
                              key={id}
                              onClick={() => {
                                setSelectedCardDetails(id);
                              }}
                            >
                              <label
                                className="form-check-label"
                                htmlFor="gridRadios2"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-circle-fill rightTick"
                                  viewBox="0 0 16 16"
                                  style={{
                                    color:
                                      selectedCardDetails.length > 0 &&
                                      selectedCardDetails === id
                                        ? "#f47321"
                                        : "",
                                  }}
                                >
                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg>
                                <span className="visa-icon">{brand}</span>
                                <h5 className="visa-heading">
                                  {brand} xxxx {last4}
                                  <span className="ecpiry-date">
                                    Expires on {exp_month}/
                                    {JSON.stringify(exp_year).slice(2)}
                                  </span>
                                </h5>
                              </label>
                              <img
                                className="delet-icon"
                                src={DeleteImg}
                                alt=""
                                onClick={() => deleteExistingCardDetails(id)}
                              ></img>
                            </div>
                          );
                        })
                      ) : (
                        <h2>Please Add Card</h2>
                      )}
                    </div>
                    <div className="mobileAddCard">
                      {/* <!-- Button trigger modal --> */}
                      <button
                        type="button"
                        className="btn AddNewCard-BTN"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <span className="plusIcon02">
                          <img
                            className="plusSecond-icon"
                            src={PlusImage}
                            alt=""
                          ></img>
                        </span>
                        Add a new card
                      </button>

                      {/* <!-- Modal --> */}
                      <div
                        className="modal fade "
                        id="exampleModal"
                        // tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Add new card
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <div className="CardDetailsMobile">
                                {/* <form> */}
                                <div className="form-group">
                                  <label htmlFor="exampleInputName">
                                    Name on card
                                  </label>
                                  <input
                                    type="name"
                                    className="form-control"
                                    id="CardInputName1"
                                    aria-describedby="nameHelp"
                                    value={cardName}
                                    onChange={(e) => {
                                      setCardName(e.target.value);
                                    }}
                                  ></input>
                                </div>
                                <div className="form-group">
                                  <label htmlFor="exampleInputCard1">
                                    Card Number
                                  </label>
                                </div>

                                <StripePaymentForm
                                  getAgainCardDetails={getExistingCardDetails}
                                  clearCardDetails={clearCardDetails}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  {/* this button is just for testing purpose until pay now button clear */}
                  <div className="text-center">
                    {selectedCardDetails.length > 0 ? (
                      <button
                        className=" btn btn-outline-primary addCard-button "
                        onClick={() => BookApointment()}
                      >
                        Pay {sessionStorage.getItem("currencySymbol")}
                        {parseFloat(
                          advancePaymentPayable + totalBookingFee
                        ).toFixed(2)}{" "}
                        Now
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="CardDetailsSection">
                <h4 className="AddCard-heading">Add new card</h4>

                <div className="form-group">
                  <label htmlFor="exampleInputName">Name on card</label>
                  <input
                    type="name"
                    className="form-control"
                    id="exampleInputName1"
                    aria-describedby="nameHelp"
                    value={cardName}
                    onChange={(e) => {
                      setCardName(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputCard1">Card Number</label>
                  <StripePaymentForm
                    getAgainCardDetails={getExistingCardDetails}
                    clearCardDetails={clearCardDetails}
                  />
                </div>
                <div className="addOnMobile mt-2">
                  <div className="addIcon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-plus-circle addMobilesvg"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    <span className="addHeadingMobile">Add a new card</span>
                    <div className="NewCardButton OnMobile text-center">
                      <button
                        type="button"
                        className="btn btn-outline-primary addCard-button OnMobile"
                      >
                        Pay {sessionStorage.getItem("currencySymbol")}
                        {parseFloat(
                          advancePaymentPayable + totalBookingFee
                        ).toFixed(2)}
                        Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading ? <Spinner></Spinner> : <></>}
      </div>
      <Modal
        className="paymentModal"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="appointment-model">
            <svg
              width="94"
              height="95"
              viewBox="0 0 94 95"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.916 52.1384L37.666 65.8776L69.7493 33.8193"
                stroke="#F47321"
                strokeWidth="2"
                strokeLinecap="square"
              />
              <path
                d="M46.8333 93.3559C72.1464 93.3559 92.6667 72.8516 92.6667 47.5583C92.6667 22.265 72.1464 1.76074 46.8333 1.76074C21.5203 1.76074 1 22.265 1 47.5583C1 72.8516 21.5203 93.3559 46.8333 93.3559Z"
                stroke="#F47321"
                strokeWidth="2"
                strokeLinecap="square"
              />
            </svg>

            <p className="apn-confirmed-model">Appointment Confirmed </p>
            <p className="date-confirm-model">
              Date: {moment(paymentData?.newDate).format("Do MMMM ")}
            </p>
            <p className="date-confirm-time">Time: {paymentData?.getTime}</p>

            <button
              className="btn btn-outline-primary addCard-button"
              style={{ marginBottom: "20px" }}
              onClick={() => addToCalander()}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: "10px" }}
              >
                <path
                  d="M1 3.12598H17V17.3371H1V3.12598Z"
                  stroke="white"
                  strokeLinecap="square"
                />
                <path d="M17 6.67871H1" stroke="white" strokeLinecap="square" />
                <path
                  d="M4.55566 3.126V1.34961"
                  stroke="white"
                  strokeLinecap="square"
                />
                <path
                  d="M13.4443 3.126V1.34961"
                  stroke="white"
                  strokeLinecap="square"
                />
                <path
                  d="M11.6663 12.0078H6.33301"
                  stroke="white"
                  strokeLinecap="square"
                />
                <path
                  d="M9 9.34375V14.6729"
                  stroke="white"
                  strokeLinecap="square"
                />
              </svg>
              Add to Calender
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showBlockModal}
        onHide={handleBlockModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <div className="blockPopup">
            <p>
              Unfortunately, your access does not allow you to book any
              appointments with {detailBarber.name}. Please choose another
              barber
            </p>
            <div className="d-flex btnDiv">
              <button
                className="btn btn-outline-primary block-button"
                style={{ marginBottom: "20px" }}
                onClick={() => chooseAnotherBarberHandler()}
              >
                Choose another
              </button>
              <button
                className="btn btn-outline-primary block-button"
                style={{ marginBottom: "20px" }}
                onClick={() => setBlockLoadMore(!blockLoadMore)}
              >
                {!blockLoadMore ? "Load More" : "Load Less"}
              </button>
            </div>
            {blockLoadMore ? (
              <p>
                It is likely that you have been blocked from booking
                appointments with this barber. This is usually because of missed
                appointments or late cancellations in the past.
              </p>
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Payment;
