import StarRatingComponent from "react-star-rating-component";
import { icBarberProfile } from "../../../utils/Images";
import "./ShopDetailsRatingsRow.style.scss";
import moment from "moment";
import { useEffect, useState } from "react";
interface Props {
  data: any;
}

const changeFormat = (epochDate: any) => {
  return moment(parseInt(epochDate)).format("ddd, DD MMM YYYY");
};

const ShopDetailsRatingsRow = ({ data }: Props) => {
  const [rating, setRating] = useState([]);

  useEffect(() => {
    const ratingList = data?.reviews?.ratings.map((item: any) => {
      return { ...item, isReadMore: true };
    });
    setRating(ratingList);
  }, [data?.reviews?.ratings]);

  const toggleReadMore = (index: any) => {
    const ratingList: any = [...rating];
    ratingList[index].isReadMore = !ratingList[index].isReadMore;
    setRating(ratingList);
  };
  return (
    <div id="Footer-comment" className="Footer-comment">
      <div className="container">
        {rating ? (
          rating.map((item: any, index: any) => {
            const {
              barberComment,
              customerComment,
              customerName,
              customerProfilePic,
              ratings,
              createdOn,
              isReadMore,
            } = item;

            return (
              <div className="row" key={index}>
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="footerComment-section">
                    <div className="img-section">
                      {customerProfilePic != null &&
                      customerProfilePic != "" ? (
                        <img
                          className="jhon_img"
                          src={`${process.env.REACT_APP_MEDIA_BASE_URL}${customerProfilePic}`}
                          alt=""
                        />
                      ) : (
                        <div className="Customer-Image">
                          {customerName ? customerName[0].toUpperCase() : ""}
                        </div>
                      )}
                    </div>

                    <div className="comment">
                      <div className="commentHeadingSec">
                        <h4 className="jhon-heading">{customerName}</h4>
                        <span>
                          <div className="commentRating-Stars">
                            {ratings ? (
                              <StarRatingComponent
                                name="rate1"
                                starCount={5}
                                value={ratings}
                              />
                            ) : (
                              <h3>No Rating Found</h3>
                            )}
                          </div>
                        </span>
                      </div>

                      <span className="jhon-date">
                        {changeFormat(createdOn)}
                      </span>
                      <p className="jhon-para desktop-view">
                        {isReadMore
                          ? customerComment.length > 250
                            ? customerComment.slice(0, 250) + "..."
                            : customerComment.slice(0, 250)
                          : customerComment}
                        {customerComment.length > 250 &&
                        customerComment.length < 501 ? (
                          <span
                            onClick={(e) => toggleReadMore(index)}
                            className="read-more"
                          >
                            {isReadMore ? "Read More" : "Show Less"}
                          </span>
                        ) : (
                          <></>
                        )}
                      </p>
                      <div className="thankyou-section desktop-view">
                        <div id="footerComment-heading-part">
                          {barberComment ? (
                            <>
                              <h6 className="leoName-heading m-0">
                                Barber's Reply
                              </h6>
                              <p className="jhon-para-small">{barberComment}</p>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="jhon-para mobile-view">
                    {isReadMore
                      ? customerComment.length > 250
                        ? customerComment.slice(0, 250) + "..."
                        : customerComment.slice(0, 250)
                      : customerComment}
                    {customerComment.length > 250 &&
                    customerComment.length < 501 ? (
                      <span
                        onClick={(e) => toggleReadMore(index)}
                        className="read-more"
                      >
                        {isReadMore ? "Read More" : "Show Less"}
                      </span>
                    ) : (
                      <></>
                    )}
                  </p>
                  <div className="thankyou-section mobile-view">
                    <div id="footerComment-heading-part">
                      {barberComment ? (
                        <>
                          <h6 className="leoName-heading m-0">
                            Barber's Reply
                          </h6>
                          <p className="jhon-para">{barberComment}</p>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            );
          })
        ) : (
          <h2>Loading ....</h2>
        )}
      </div>
    </div>
  );
};
export default ShopDetailsRatingsRow;
