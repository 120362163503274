import StarRatingComponent from "react-star-rating-component";
import { icBarberCut, icLocationOrange } from "../../../utils/Images";
import { useNavigate } from "react-router";
import { ratingConvert } from "../../shopDetails/shopDetailsRatings/ShopDetailsRatings";
import { useEffect } from "react";

interface Props {
  data: any;
  index: number;
  showDistance: () => boolean;
}

const BarbarCard = ({ data, index, showDistance }: Props) => {
  const navigate = useNavigate();
  const navigateToDetailPage = () => {
    const barber = {
      colleagueId: data?.providerId,
      isBlock: false,
      isProfileCompleted: true,
      isActive: true,
      name: data?.name,
      profilePic: data?.profilePic,
      rating: data?.ratings,
      reviewCount: data?.reviewCount,
    };

    sessionStorage.setItem("barberDetails", JSON.stringify(barber));
    navigate(`/barber/${data?.providerId}`, data?.providerId);
  };

  const showColleaguesPopUp =
    sessionStorage.getItem("showColleaguesPopUp") == null
      ? {}
      : JSON.parse(sessionStorage.getItem("showColleaguesPopUp") || "{}");

  useEffect(() => {
    if (showColleaguesPopUp.hasOwnProperty(data?.providerId)) {
      delete showColleaguesPopUp[data?.providerId];
      sessionStorage.setItem(
        "showColleaguesPopUp",
        JSON.stringify(showColleaguesPopUp)
      );
    }
  }, []);

  return (
    <>
      <div
        key={index}
        className=" col-sm-12 col-md-6 col-lg-4"
        onClick={() => navigateToDetailPage()}
      >
        <div className="barbarSearch">
          <div>
            <img
              className="BarbarImage"
              src={`${process.env.REACT_APP_MEDIA_BASE_URL}${data.bussinessProfile}`}
              alt=""
            />
          </div>
          <div className="DetailsSearch">
            <h4 className="BarbarName">{data?.bussinessName}</h4>
            <div className="ellipsis">
              <span>
                <svg
                  className="BarbarLoction-icon"
                  width="18"
                  height="25"
                  viewBox="0 0 18 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.26833 21C14.6328 16.3333 17.315 11.8889 17.315 7.66667C17.315 5.89856 16.4672 4.20286 14.9582 2.95262C13.4491 1.70238 11.4024 1 9.26833 1C7.13422 1 5.08753 1.70238 3.57849 2.95262C2.06945 4.20286 1.22168 5.89856 1.22168 7.66667C1.22168 11.8889 3.9039 16.3333 9.26833 21Z"
                    stroke="#F47321"
                    strokeMiterlimit="8"
                    strokeLinecap="square"
                  />
                  <path
                    d="M9.2685 11C10.3795 11 11.2802 10.1046 11.2802 9C11.2802 7.89543 10.3795 7 9.2685 7C8.15749 7 7.25684 7.89543 7.25684 9C7.25684 10.1046 8.15749 11 9.2685 11Z"
                    stroke="#FD6A02"
                    strokeMiterlimit="8"
                    strokeLinecap="square"
                  />
                </svg>
              </span>
              <span>
                {data?.address ? data?.address : data?.counteryDetails?.name}
              </span>
            </div>
            <div>
              {showDistance() && (
                <h6 style={{ color: "#f47321" }}>
                  <i>{Number(data?.distance).toFixed(2)} miles away</i>
                </h6>
              )}
            </div>
            <div className="barbarRatingSec">
              <StarRatingComponent
                name="rate"
                starCount={5}
                value={ratingConvert(data?.ratings)}
              />
              <span className="ReviesHeading">
                ({data?.reviewCount} Reviews)
              </span>
            </div>
            <div>
              <h6 className="PaymentRequired">
                <span>
                  {data?.isAdvancePayment ? "Advance Payment required" : ""}
                </span>
              </h6>
            </div>
          </div>
          {/* <div className="viewMoreSec">
            <div className="viewMore">
              <span>View More Details</span>
            </div>
            <div className="viewIcon">
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default BarbarCard;
