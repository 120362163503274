import { ShopDetailsColleagues } from "./shopDetailsColleagues/ShopDetailsColleagues";
import ShopDetailsHeader from "./shopDetailsHeader/ShopDetailsHeader";
import ShopDetailsMap from "./shopDetailsMap/ShopDetailsMap";
import ShopDetailsRatings from "./shopDetailsRatings/ShopDetailsRatings";
import ShopDetailsServices from "./shopDetailsServices/ShopDetailsServices";
import "./ShopDetails.style.scss";
import { HttpCallGet } from "../../services/NetworkCall";
import { BARBER_DETAILS } from "../../services/EndPoints";
import { useEffect, useState } from "react";
import ShopDetailsRatingsRow from "./shopDetailsRatingsRow/ShopDetailsRatingsRow";
import Spinner from "../../Shared/Spinner/Spinner";
import ImageSliderSection from "../../components/imageSliderSection/imageSliderSection";
import SocialMediaIcons from "../../components/socialMediaIcons/socialMediaIcons";
import { useLocation } from "react-router-dom";
import { getProviderIdFromUrl } from "../../utils/filter";

const ShopDetails = (props:any) => {
  const [jsonData, setJsonData] = useState({});
  const { pathname } = useLocation();
  const providerId = getProviderIdFromUrl(pathname);
  const [loading, setLoading] = useState(false);
  const [prize, setPrize] = useState(0);
  const [serviceSelected, setServiceSelected] = useState<any[]>([]);

  useEffect(() => {
    if (providerId) {
      FetchBarberList(providerId);
    }
  }, [providerId]);

  const FetchBarberList = (id: any) => {
    setLoading(true);
    HttpCallGet(
      `${BARBER_DETAILS}/${id}`,
      sessionStorage.getItem("AUTHTOKEN_USER")
    )
      .then((response: any) => {
        setLoading(false);
        setJsonData(response?.data?.data);
        sessionStorage.setItem(
          "currencySymbol",
          response?.data?.data?.country?.currencySymbol
        );
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  return (
    <>
      <div className="mobile-view">
        <ImageSliderSection data={jsonData} />
      </div>
      <ShopDetailsHeader data={jsonData} />
      <div className="mobile-view">
        <SocialMediaIcons data={jsonData} />
      </div>
      <div className="desktop-view">
        <ShopDetailsMap data={jsonData} />
      </div>
      <ShopDetailsColleagues
        data={jsonData}
        prize={prize}
        setPrize={setPrize}
        serviceSelected={serviceSelected}
        setServiceSelected={setServiceSelected}
      />
      <ShopDetailsServices
        data={jsonData}
        prize={prize}
        setPrize={setPrize}
        serviceSelected={serviceSelected}
        setServiceSelected={setServiceSelected}
      />
      <ShopDetailsRatings data={jsonData} />
      <ShopDetailsRatingsRow data={jsonData} />
      {loading ? <Spinner></Spinner> : <></>}
    </>
  );
};
export default ShopDetails;
