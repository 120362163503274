import "./ShopDetailsMap.style.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ImageSliderSection from "../../../components/imageSliderSection/imageSliderSection";
import SocialMediaIcons from "../../../components/socialMediaIcons/socialMediaIcons";
import { useEffect, useState } from "react";
import sliderimg1 from "../../../assets/hair cuting style-image.png";
import HairCutting from "../../../assets/shop-image.png";
import { url } from "inspector";
import { Carousel } from "react-responsive-carousel";
interface Props {
  data: any;
}

const ShopDetailsMap = ({ data }: Props) => {
  const [shopImages, setShopImages] = useState(["1", "2", "3"]);
  const [visibleImageIndex, setVisibleImageIndex] = useState(0);

  return (
    <div className="MapBackground-section">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-5 col-lg-5 ">
            <div className="map-section">
              <div style={{ width: "100%" }}>
                <iframe
                  width="100%"
                  height="600"
                  frameBorder="0"
                  scrolling="no"
                  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCW2rD1iN9Jt7LP0N5R4t2NE5L9QME1bFg&q=${data?.lat}+${data?.lng}
                  &zoom=17`}
                >
                  <a href="https://www.gps.ie/farm-gps/">GPS Navigation</a>
                </iframe>
              </div>
            </div>
            <SocialMediaIcons data={data} />
          </div>
          <div className="col-sm-12 col-md-7 col-lg-7">
            <ImageSliderSection data={data} />
            {/* <div id="thumbnail" className="thumbnail left">
              <ul className="thumbnail-list">
                <li className="active">
                  <img
                    className="thumbnail-hair-cutting-image"
                    src={
                      process.env.REACT_APP_MEDIA_BASE_URL +
                      data?.imagesArray[
                        visibleImageIndex == 0
                          ? visibleImageIndex
                          : visibleImageIndex - 1
                      ]
                    }
                    alt=""
                  />
                </li>
                <li className="">
                  <img
                    className="thumbnail-hair-cutting-image"
                    src={
                      process.env.REACT_APP_MEDIA_BASE_URL +
                      data?.imagesArray[
                        visibleImageIndex == 0
                          ? visibleImageIndex + 1
                          : visibleImageIndex
                      ]
                    }
                    alt=""
                  />
                </li>
                <li className="image-border">
                  <img
                    className="thumbnail-hair-cutting-image"
                    src={
                      process.env.REACT_APP_MEDIA_BASE_URL +
                      data?.imagesArray[
                        visibleImageIndex - 1 === data?.imagesArray.length
                          ? visibleImageIndex
                          : visibleImageIndex == 0
                          ? visibleImageIndex + 2
                          : visibleImageIndex + 1
                      ]
                    }
                    alt=""
                  />
                </li>
                <li className="marker"></li>
              </ul>
            </div> */}
            {/* old slder start */}
            {/* <div className="mobile-view-slider">
              <div
                id="carousel-example-generic"
                className="carousel slide"
                data-ride="carousel"
              >
                <ol className="carousel-indicators">
                  <li
                    data-target="#carousel-example-generic"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li
                    data-target="#carousel-example-generic"
                    data-slide-to="1"
                  ></li>
                  <li
                    data-target="#carousel-example-generic"
                    data-slide-to="2"
                  ></li>
                </ol>
                <div className="carousel-inner" role="listbox">
                  <div className="item active">
                    <img
                      className="mobile-view-sliderImg"
                      src={`{sliderimg1}`}
                      alt=""
                    />
                    <div className="carousel-caption">
                      <h3>First Slide</h3>
                      <p>
                        Caption goes here
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className="item">
                    <img
                      className="mobileViewsliderImg02"
                      src={`{sliderimg1}`}
                      alt=""
                    />
                    <div className="carousel-caption">
                      <h3>Second slide</h3>
                      <p>Caption goes here</p>
                    </div>
                  </div>
                  <div className="item">
                    <img
                      className="mobileViewsliderImg03"
                      src={`{sliderimg1}`}
                      alt=""
                    />
                    <div className="carousel-caption">
                      <h3>Third slide</h3>
                      <p>Caption goes here</p>
                    </div>
                  </div>
                </div>
                <a
                  className="left carousel-control"
                  href="#carousel-example-generic"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    className="glyphicon glyphicon-chevron-left"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="right carousel-control"
                  href="#carousel-example-generic"
                  role="button"
                  data-slide="next"
                >
                  <span
                    className="glyphicon glyphicon-chevron-right"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>
            </div> */}
            {/* <!-- END --> */}
            {(data?.imagesArray?.length <= 3) && (
              <Carousel
                showIndicators={true}
                showThumbs={false}
              >
                {data?.imagesArray?.map((item: any, index: number) => {
                  return (
                    <div
                      style={{ width: "100%", backgroundColor: "BLACK" }}
                      key={index}
                    >
                      <img
                        className="thumbnail-hair-cutting-image"
                        // style={{ objectFit: "cover", height: "300px" }}
                        src={process.env.REACT_APP_MEDIA_BASE_URL + item}
                        alt=""
                      />
                    </div>
                  );
                })}
              </Carousel>
            )}
            {data?.imagesArray?.length > 3 && (
              <article id="slider">
                <div id="slide" className="slide left tab-none">
                  <ul>
                    <li className="active">
                      <div
                        className="img"
                      >
                        <img
                          className="slider_images"
                          src={
                            process.env.REACT_APP_MEDIA_BASE_URL +
                            data?.imagesArray[visibleImageIndex]
                          }
                          alt=""
                        />
                      </div>
                    </li>
                  </ul>
                </div>
                <div id="thumbnail" className="thumbnail left">
                  <ul className="thumbnail-list">
                    <li
                      className="active"
                      onClick={() =>
                        setVisibleImageIndex(
                          visibleImageIndex === 0
                            ? visibleImageIndex
                            : visibleImageIndex + 1 ===
                              data?.imagesArray?.length
                            ? visibleImageIndex - 2
                            : visibleImageIndex - 1
                        )
                      }
                    >
                      <img
                        className="thubnail-shop-image"
                        src={
                          process.env.REACT_APP_MEDIA_BASE_URL +
                          data?.imagesArray[
                            visibleImageIndex === 0
                              ? visibleImageIndex
                              : visibleImageIndex + 1 ===
                                data?.imagesArray?.length
                              ? visibleImageIndex - 2
                              : visibleImageIndex - 1
                          ]
                        }
                        alt=""
                      />
                    </li>
                    <li
                      className=""
                      onClick={() =>
                        setVisibleImageIndex(
                          visibleImageIndex === 0
                            ? visibleImageIndex + 1
                            : visibleImageIndex + 1 ===
                              data?.imagesArray?.length
                            ? visibleImageIndex - 1
                            : visibleImageIndex
                        )
                      }
                    >
                      <img
                        className="thubnail-shop-image"
                        src={
                          process.env.REACT_APP_MEDIA_BASE_URL +
                          data?.imagesArray[
                            visibleImageIndex === 0
                              ? visibleImageIndex + 1
                              : visibleImageIndex + 1 ===
                                data?.imagesArray?.length
                              ? visibleImageIndex - 1
                              : visibleImageIndex
                          ]
                        }
                        alt=""
                      />
                    </li>
                    <li
                      className=""
                      onClick={() => {
                        setVisibleImageIndex(
                          visibleImageIndex + 1 === data?.imagesArray?.length
                            ? visibleImageIndex
                            : visibleImageIndex === 0
                            ? visibleImageIndex + 2
                            : visibleImageIndex + 1
                        );
                      }}
                    >
                      <img
                        className="thubnail-shop-image"
                        src={
                          process.env.REACT_APP_MEDIA_BASE_URL +
                          data?.imagesArray[
                            visibleImageIndex + 1 === data?.imagesArray?.length
                              ? visibleImageIndex
                              : visibleImageIndex === 0
                              ? visibleImageIndex + 2
                              : visibleImageIndex + 1
                          ]
                        }
                        alt=""
                      />
                    </li>
                    <li className="marker"></li>
                  </ul>
                </div>
              </article>
            )}
            {/* old slider end */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopDetailsMap;
