import { useEffect, useState } from "react";
import "./imageSliderSection.style.scss";
import { Carousel } from "react-responsive-carousel";

interface Props {
  data: any;
}

const ImageSliderSection = ({ data }: Props) => {
  const [shopImages, setShopImages] = useState(["1", "2", "3"]);
  const [visibleImageIndex, setVisibleImageIndex] = useState(0);

  return (
    <>
      <article id="slider">
        <div id="slide" className={data?.imagesArray?.length > 3 ? 'tablet-view-multiple' : "slide tablet-view"}>
          <Carousel
            showIndicators={true}
            showThumbs={false}
            onChange={(index) => setVisibleImageIndex(index)}
          >
            {data?.imagesArray?.map((item: any, index: number) => {
              return (
                <div
                  style={{ width: "100%", backgroundColor: "BLACK" }}
                  key={index}
                >
                  <img
                    className="thumbnail-hair-cutting-image"
                    // style={{ objectFit: "cover", height: "300px" }}
                    src={process.env.REACT_APP_MEDIA_BASE_URL + item}
                    alt=""
                  />
                </div>
              );
            })}
          </Carousel>
          {/* {data?.imagesArray?.length > 0 && (
            <img
              className="thumbnail-hair-cutting-image"
              src={process.env.REACT_APP_MEDIA_BASE_URL + data?.imagesArray[visibleImageIndex]}
              alt=""
            />
          )}
          {data?.imagesArray?.length > 0 && (
            <div id="thumbnail" className="thumbnail left">
              <ul className="thumbnail-list">
                <li
                  className="active"
                  onClick={() => setVisibleImageIndex(visibleImageIndex + 1)}
                >
                  <img
                    className="thumbnail-hair-cutting-image"
                    src={
                      process.env.REACT_APP_MEDIA_BASE_URL +
                      data?.imagesArray[
                        visibleImageIndex == 0
                          ? visibleImageIndex
                          : visibleImageIndex - 1
                      ]
                    }
                    alt=""
                  />
                </li>
                <li
                  className=""
                  onClick={() => setVisibleImageIndex(visibleImageIndex + 1)}
                >
                  <img
                    className="thumbnail-hair-cutting-image"
                    src={
                      process.env.REACT_APP_MEDIA_BASE_URL +
                      data?.imagesArray[
                        visibleImageIndex == 0
                          ? visibleImageIndex + 1
                          : visibleImageIndex
                      ]
                    }
                    alt=""
                  />
                </li>
                <li className="image-border">
                  <img
                    className="thumbnail-hair-cutting-image"
                    src={
                      process.env.REACT_APP_MEDIA_BASE_URL +
                      data?.imagesArray[
                        visibleImageIndex - 1 === data?.imagesArray.length
                          ? visibleImageIndex
                          : visibleImageIndex == 0
                          ? visibleImageIndex + 2
                          : visibleImageIndex + 1
                      ]
                    }
                    alt=""
                  />
                </li>
                <li className="marker"></li>
              </ul>
            </div>
          )} */}
        </div>
      </article>
    </>
  );
};

export default ImageSliderSection;
