import { Button } from "react-bootstrap";
import "./timerStyle.scss";
import xMark from "../../assets/xmark.svg";
import plusSolid from "../../assets/plusIcon.svg";
import { useEffect, useState } from "react";
import moment from "moment";
import { time } from "console";
interface Props {
  data?: any;
  newDateofTime?: any;
  getTimerSlotMethod?: any;
  itemSelected?: any;
  removeItem?: any;
  gettingTimeDetails?: any;
  selectedServicePrize?: any;
  selectedServiceTime?: any;
  addMoreService?: any;
  getTimeFromUser?: any;
  getCommentFromUser?: any;
}

export const TimerSlider = ({
  getTimeFromUser,
  getTimerSlotMethod,
  itemSelected,
  removeItem,
  gettingTimeDetails,
  selectedServicePrize,
  selectedServiceTime,
  addMoreService,
  data,
  getCommentFromUser,
}: Props) => {
  const [classChanger, setClassChanger] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [times, setTimes] = useState([]);

  const paginate = () => {
    setTimes(gettingTimeDetails.slice(0, pageNumber * pageSize));
  };

  useEffect(() => {
    paginate();
  }, [gettingTimeDetails, pageNumber]);

  const toggleReadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  return (
    <>
      <div className="timer-slide">
        <h4 className="time-name">Time</h4>
        {times.map((item: any) => {
          return (
            <button
              className={
                item === classChanger ? "timer-btn active" : "timer-btn"
              }
              key={item}
              onClick={() => {
                getTimeFromUser(item);
                setClassChanger(item);
                const scrollFromCalender =
                  document.querySelector(".calender-tag");
                scrollFromCalender?.scrollBy({ top: 400, behavior: "smooth" });
              }}
            >
              {item}
            </button>
          );
        })}
        {times.length > 0 && times.length !== gettingTimeDetails.length ? (
          <p onClick={toggleReadMore} className="read-more">
            Load More
          </p>
        ) : (
          <></>
        )}
        {times.length === 0 ? "No Slots Available" : ""}
        <div className="selected-services">
          <h4 className="serivice-name">Selected Services</h4>
          <div className="serviceSelectedBTN">
            {itemSelected
              ? itemSelected.map((item: any, index: any) => {
                  return (
                    <div key={index}>
                      <Button variant=" btn SelectedBTN">
                        {item.title}
                        <img
                          src={xMark}
                          alt="there is an img"
                          className="x-marksvg"
                          onClick={() => removeItem(item.id)}
                        />
                      </Button>
                    </div>
                  );
                })
              : null}
          </div>
        </div>

        {/* add another services section start here */}

        <div className="add-another-services">
          <span onClick={() => addMoreService()}>
            <img src={plusSolid} className="x-marksvg" />
            <span>Add Another Service</span>
          </span>
        </div>
        <div className="addComment-service">
          <span>
            <i className="fa fa-comment-o CommentIcon" aria-hidden="true"></i>
          </span>
          <span className="commentHeading">Add a comment</span>
        </div>
        <div>
          <textarea
            className="addComment"
            maxLength={500}
            onChange={(e) => {
              getCommentFromUser(e.target.value);
            }}
          />
        </div>

        {/***Cost Section goes here */}

        <div className="cost-section">
          <div className="first-container">Cost</div>
          <div className="second-container">
            {sessionStorage.getItem("currencySymbol")}
            {selectedServicePrize}
            <p className="est-time">{selectedServiceTime} minutes</p>
          </div>
        </div>

        <div className="cnf-booking">
          <Button
            className="cnf-btn"
            onClick={() => getTimerSlotMethod()}
            disabled={itemSelected.length === 0 || classChanger === null}
          >
            Confirm Booking
          </Button>
          <br />
          <div className="smalText">
            <p className="cnf-booking-small">
              Please note: If you cancel or reschedule with less than 24hours’
              notice, any advance payments made will not be refunded.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
