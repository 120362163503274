import moment from "moment";
import { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Calender from "../../../components/Calender/Calender";
import { TimerSlider } from "../../../components/Time-Slider/TimeSlider";
import { PaymentPath, VerifyPath } from "../../../routes/RouterPath";
import {
  CUSTOMER_BOOKING_CONFIRM,
  CUSTOMER_DATE_BOOKINGS,
  CUSTOMER_GET_PROFILE_DETAILS,
} from "../../../services/EndPoints";
import { HttpCallGet, HttpCallPost } from "../../../services/NetworkCall";
import { ToastOnFailure } from "../../../Shared/Toast/Toast";
import "./ShopDetailsServices.style.scss";
import { Modal } from "react-bootstrap";
import CalendarApi from "../../../services/AppointmentCalender";

interface Props {
  data?: any;
  newDateofTime?: any;
  prize?:any;
  setPrize?:any;
  serviceSelected?:any;
  setServiceSelected?:any;
}

const ShopDetailsServices = ({ data, prize,setPrize , serviceSelected, setServiceSelected, newDateofTime = new Date() }: Props) => {
  const [show, setShow] = useState(false);
  const [newDate, setnewDate] = useState(new Date());
  const [serviceArr, setServiceArr] = useState<any[]>([]);
  const [gettingTimeDetails, setGettingTimeDetails] = useState([]);
  const [getTime, setGetTime] = useState();
  const [time, setTime] = useState(0);
  const [comment, setComment] = useState("");
  const { id } = useParams()

  const location = useLocation();

  ///model show hooks here
  const [defaultShow, setDefaultSetShow] = useState(false);
  const handleOnClose = () => {
    setDefaultSetShow(false);
    setPrize(0);
    setServiceSelected([]);
    setShow(false);
  };
  const navigate = useNavigate();


  if (sessionStorage.getItem("paymentData")) {
    sessionStorage.removeItem("paymentData");
  }

  const handleShow = () => {
    setShow(true);
    sessionStorage.setItem("colleagueId", id || "")
  };

  const handleClose = () => {
    setShow(false);
    setServiceSelected([]);
    setnewDate(new Date());
    setGettingTimeDetails([]);
  };

  const addMoreService = () => {
    setShow(false);
  };

  const timeInMinutes = (time: any) => {
    const splitTime = time.split("h:");
    const splitminuts = splitTime[1].split("m");
    return parseInt(splitTime[0]) * 60 + parseInt(splitminuts[0]);
  };

  const calculatePrizeAndTime = () => {
    const prizeTotal = serviceSelected.reduce(function (a: any, b: any) {
      return a + b.price;
    }, 0);
    setPrize(prizeTotal);

    const timeTotal = serviceSelected.reduce(function (a: any, b: any) {
      return a + timeInMinutes(b.duration);
    }, 0);
    setTime(timeTotal);
  };

  const getTimeFromUser = (time: any) => {
    setGetTime(time);
  };

  const getCommentFromUser = (comment: any) => {
    setComment(comment);
  };

  useEffect(() => {
    data?.id && GetTimeSlot();
  }, [newDate]);

  const getData = (date: any) => {
    setnewDate(date);
  };

  const GetTimeSlot = () => {
    if (serviceSelected.length > 0 && newDate != null) {
      let value = {
        date: Number(moment(newDate).format("x")),
        providerId: data?.id,
        serviceIds: serviceSelected
          ? serviceSelected.map((list: any) => list.id)
          : null,
      };
      HttpCallPost(CUSTOMER_DATE_BOOKINGS, value)
        .then((response: any) => {
          if (response.data.status == 200) {
            setGettingTimeDetails(response?.data.data || []);
          } else if (response.data.status == 400) {
            ToastOnFailure(response.data.msg);
          } else {
            ToastOnFailure(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setGettingTimeDetails([]);
    }
  };

  const getCheckedValue = (value: any) => {
    const serviceSelectedArray = [...serviceSelected];

    const index = serviceSelectedArray.findIndex((res: any) => {
      return res.id == value.id;
    });

    if (index > -1) {
      serviceSelectedArray.splice(index, 1);
    } else {
      serviceSelectedArray.push(value);
    }

    setServiceSelected(serviceSelectedArray);
  };

  useEffect(() => {
    setServiceArr(data?.services);
  }, [data?.services]);

  useEffect(() => {
    GetTimeSlot();
    calculatePrizeAndTime();
  }, [serviceSelected]);

  // remove item if customer want to remove some services

  const removeItem = (id: any) => {
    const item = serviceSelected.filter((item: any) => item.id !== id);
    setServiceSelected(item);
  };

  const checkSelectedColor = (item: any) => {
    const index = serviceSelected.findIndex((value: any) => {
      return value.id == item.id;
    });
    return index > -1 ? true : false;
  };

  const addToCalander = () => {
    const calanderTitle = serviceSelected
      .map((res: any) => {
        return res.title.trim();
      })
      .join(", ");

    const startDate = new Date(
      moment(newDate).format("YYYY-MM-DD") + " " + getTime
    );
    const endDate = new Date(
      moment(new Date(moment(newDate).format("YYYY-MM-DD") + " " + getTime))
        .add(time, "m")
        .format()
    );

    HttpCallGet(
      CUSTOMER_GET_PROFILE_DETAILS,
      sessionStorage.getItem("AUTHTOKEN_USER")
    )
      .then((response: any) => {
        CalendarApi(
          response?.data?.data?.email,
          startDate,
          endDate,
          calanderTitle
        );
      })
      .catch((err: any) => {
        console.log(err.message);
      });
  };

  const NavigatePaymentScreen = () => {
    if (data.isAdvancePayment) {
      navigate(PaymentPath, {
        state: {
          data: data,
          serviceSelected: serviceSelected,
          getTime: getTime,
          newDate: newDate,
          reDirectionUrl: `/payment`,
          prize: prize,
          advancePaymentAmount: data?.advancePaymentAmount,
          time: time,
          comment: comment,
        },
        replace: true,
      });
    } else {
      // write your logic here
      let defaultData = {
        colleagueId: data?.id,
        time: getTime,
        date: Number(moment(newDate).format("x")),
        serviceIds: serviceSelected
          ? serviceSelected.map((list: any) => list.id)
          : null,
        bookingCharge: data?.bookingCharges,
        duration: time,
        customerComment: comment,
      };

      const token = sessionStorage.getItem("AUTHTOKEN_USER");
      if (token) {
        HttpCallPost(CUSTOMER_BOOKING_CONFIRM, defaultData, token)
          .then((response: any) => {
            if (response.data.status == 200) {
              setDefaultSetShow(true);
            } else if (response.data.status == 400) {
              ToastOnFailure(response.data.msg);
            } else {
              ToastOnFailure(response.data.msg);
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403) {
              ToastOnFailure("Please login");
            }
            if (error?.response?.status == 401) {
              ToastOnFailure("Please login again session expired");
            }
            sessionStorage.setItem("previousUrl", location.pathname);
            if (sessionStorage.getItem("AUTHTOKEN_USER")) {
              sessionStorage.removeItem("AUTHTOKEN_USER");
            }
            if (sessionStorage.getItem("USER_ID")) {
              sessionStorage.removeItem("USER_ID");
            }
            navigate("/login?tab=1", { replace: true });
          });
      } else {
        ToastOnFailure("Please login");
        sessionStorage.setItem("previousUrl", location.pathname);
        if (sessionStorage.getItem("AUTHTOKEN_USER")) {
          sessionStorage.removeItem("AUTHTOKEN_USER");
        }
        if (sessionStorage.getItem("USER_ID")) {
          sessionStorage.removeItem("USER_ID");
        }
        navigate("/login?tab=1", { replace: true });
      }
    }
  };

  const maxDate = new Date(
    moment().add(data?.schedules?.advanceBookWeeks, "w").format("YYYY-MM-DD")
  );

  return (
    <div className="serviceSection">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div id="service-section" className="service-section">
              <div
                id="service-headingSection"
                className="service-headingSection"
              >
                <h2 id="service-heading" className="service-heading">
                  Services
                </h2>
                <h6 className="selectYourService">Select your service(s)</h6>
              </div>
              <div id="service-components" className="service-components">
                {serviceArr?.map((item: any, index: any) => {
                  return (
                    <div
                      className="gents-cut-details"
                      onClick={() => getCheckedValue(item)}
                      key={item.id}
                      style={{
                        backgroundColor: checkSelectedColor(item)
                          ? "#f4f4f4"
                          : "",
                      }}
                    >
                      <div>
                        <i
                          className="fa fa-check"
                          aria-hidden="true"
                          style={{
                            color: checkSelectedColor(item) ? "#f47321" : "",
                            cursor: "pointer",
                            borderColor: checkSelectedColor(item)
                              ? "#f47321"
                              : "",
                          }}
                        ></i>
                      </div>
                      <div className="service-subdiv">
                        <h5
                          className="gents-heading"
                          style={{
                            color: checkSelectedColor(item) ? "#f47321" : "",
                          }}
                        >
                          {item?.title}
                        </h5>
                        <span
                          className="price"
                          style={{
                            color: checkSelectedColor(item) ? "#f47321" : "",
                          }}
                        >
                          {item?.countery?.currencySymbol}
                          {item?.price}
                        </span>
                        <br />
                        <h6
                          className="gents-cut-time"
                          style={{
                            color: checkSelectedColor(item) ? "#f47321" : "",
                          }}
                        >
                          {item?.duration.replace("0h:", "")}
                        </h6>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* cost section */}
              {serviceSelected.length > 0 ? (
                <div className="costSection">
                  <h4 className="costHeading">Cost</h4>
                  <div className="totalCostSection">
                    <h5 className="totalCost">
                      {sessionStorage.getItem("currencySymbol")}
                      {prize}
                    </h5>
                    <h6 className="totalMinutes">({time} Minutes)</h6>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {serviceSelected.length > 0 ? (
                <div className="costSection Mobile">
                  <div className="MobileCostSec">
                    <h4 className="totalTime">Total time</h4>
                    <h4 className="costHeading">Cost</h4>
                  </div>
                  <div className="totalCostSection">
                    <h6 className="totalMinutes">({time} Minutes)</h6>
                    <h5 className="totalCost">{sessionStorage.getItem("currencySymbol")}{prize}</h5>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="select-btnSection mobile-view">
                <button
                  type="button"
                  className="select-date"
                  onClick={handleShow}
                  disabled={serviceSelected.length === 0}
                  style={{
                    backgroundColor:
                      serviceSelected.length > 0 ? "#f47321" : "",
                    color: serviceSelected.length > 0 ? "white" : "",
                  }}
                >
                  Book
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="hours-deatils">
              <div className="opening-hours-section">
                <h2 className="opening-heading">Opening Hours</h2>
                <div className="MoreTime">
                  <div className="opening-time">
                    <div className="day-and-time">
                      <h5
                        className={
                          data?.schedules?.monday?.length === 0
                            ? "monday closed"
                            : "monday"
                        }
                      >
                        Monday
                      </h5>
                      <div className="doubleTime">
                        {data?.schedules?.monday?.map(
                          (item: any, index: number) => {
                            return (
                              <h6 className="time" key={index}>
                                {item}
                              </h6>
                            );
                          }
                        )}
                      </div>
                      {data?.schedules?.monday?.length === 0 && (
                        <h6 className="time closed">Closed</h6>
                      )}
                    </div>
                  </div>
                  <div className="day-and-time">
                    <h5
                      className={
                        data?.schedules?.tuesday?.length === 0
                          ? "monday closed"
                          : "monday"
                      }
                    >
                      Tuesday
                    </h5>
                    <div className="doubleTime">
                      {data?.schedules?.tuesday?.map(
                        (item: any, index: number) => {
                          return (
                            <h6 className="time" key={index}>
                              {item}
                            </h6>
                          );
                        }
                      )}
                    </div>
                    {data?.schedules?.tuesday?.length === 0 && (
                      <h6 className="time closed">Closed</h6>
                    )}
                  </div>
                  <div className="day-and-time">
                    <h5
                      className={
                        data?.schedules?.wednesday?.length === 0
                          ? "monday closed"
                          : "monday"
                      }
                    >
                      Wednesday
                    </h5>{" "}
                    <div className="doubleTime">
                      {data?.schedules?.wednesday?.map(
                        (item: any, index: number) => {
                          return (
                            <h6 className="time" key={index}>
                              {item}
                            </h6>
                          );
                        }
                      )}
                    </div>
                    {data?.schedules?.wednesday?.length === 0 && (
                      <h6 className="time closed">Closed</h6>
                    )}
                  </div>
                  <div className="day-and-time">
                    <h5
                      className={
                        data?.schedules?.thursday?.length === 0
                          ? "monday closed"
                          : "monday"
                      }
                    >
                      Thursday
                    </h5>
                    <div className="doubleTime">
                      {data?.schedules?.thursday?.map(
                        (item: any, index: number) => {
                          return (
                            <h6 className="time" key={index}>
                              {item}
                            </h6>
                          );
                        }
                      )}
                    </div>
                    {data?.schedules?.thursday?.length === 0 && (
                      <h6 className="time closed">Closed</h6>
                    )}
                  </div>
                  <div className="day-and-time">
                    <h5
                      className={
                        data?.schedules?.friday?.length === 0
                          ? "monday closed"
                          : "monday"
                      }
                    >
                      Friday
                    </h5>
                    <div className="doubleTime">
                      {data?.schedules?.friday?.map(
                        (item: any, index: number) => {
                          return (
                            <h6 className="time" key={index}>
                              {item}
                            </h6>
                          );
                        }
                      )}
                    </div>
                    {data?.schedules?.friday?.length === 0 && (
                      <h6 className="time closed">Closed</h6>
                    )}
                  </div>
                  <div className="day-and-time">
                    <h5
                      className={
                        data?.schedules?.saturday?.length === 0
                          ? "monday closed"
                          : "monday"
                      }
                    >
                      Saturday
                    </h5>
                    <div className="doubleTime">
                      {data?.schedules?.saturday?.map(
                        (item: any, index: number) => {
                          return (
                            <h6 className="time" key={index}>
                              {item}
                            </h6>
                          );
                        }
                      )}
                    </div>
                    {data?.schedules?.saturday?.length === 0 && (
                      <h6 className="time closed">Closed</h6>
                    )}
                  </div>
                  <div className="day-and-time">
                    <h5
                      className={
                        data?.schedules?.sunday?.length === 0
                          ? "monday closed"
                          : "monday"
                      }
                    >
                      Sunday
                    </h5>
                    <div className="doubleTime">
                      {data?.schedules?.sunday?.map(
                        (item: any, index: number) => {
                          return (
                            <h6 className="time" key={index}>
                              {item}
                            </h6>
                          );
                        }
                      )}
                    </div>
                    {data?.schedules?.sunday?.length === 0 && (
                      <h6 className="time closed">Closed</h6>
                    )}
                  </div>
                </div>
                <div className="select-btnSection desktop-view">
                  <button
                    type="button"
                    className="select-date"
                    onClick={handleShow}
                    disabled={serviceSelected.length === 0}
                    style={{
                      backgroundColor:
                        serviceSelected.length > 0 ? "#f47321" : "",
                      color: serviceSelected.length > 0 ? "white" : "",
                    }}
                  >
                    Select Date & Time
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={{ width: "515px", padding: "25px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ color: "#f47321" }}>
            <h4 className="SelectDateHeading">Select Date</h4>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body
          className="calender-tag"

        >
          <Calender
            newDate={getData}
            selectedData={newDate}
            maxDate={maxDate}
            schedules={data?.schedules}
          />
          <TimerSlider
            data={data}
            newDateofTime={newDateofTime}
            getTimerSlotMethod={() => NavigatePaymentScreen()}
            getTimeFromUser={getTimeFromUser}
            itemSelected={serviceSelected}
            removeItem={removeItem}
            gettingTimeDetails={gettingTimeDetails}
            selectedServicePrize={prize}
            selectedServiceTime={time}
            addMoreService={addMoreService}
            getCommentFromUser={getCommentFromUser}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={defaultShow}
        onHide={handleOnClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="appointment-model">
            <svg
              width="94"
              height="95"
              viewBox="0 0 94 95"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.916 52.1384L37.666 65.8776L69.7493 33.8193"
                stroke="#F47321"
                strokeWidth="2"
                strokeLinecap="square"
              />
              <path
                d="M46.8333 93.3559C72.1464 93.3559 92.6667 72.8516 92.6667 47.5583C92.6667 22.265 72.1464 1.76074 46.8333 1.76074C21.5203 1.76074 1 22.265 1 47.5583C1 72.8516 21.5203 93.3559 46.8333 93.3559Z"
                stroke="#F47321"
                strokeWidth="2"
                strokeLinecap="square"
              />
            </svg>

            <p className="apn-confirmed-model">Appointment Confirmed </p>
            <p className="date-confirm-model">
              Date: {moment(newDate).format("Do MMMM ")}
            </p>
            <p className="date-confirm-time">Time: {getTime}</p>

            <button
              className="btn btn-outline-primary addCard-button"
              style={{ marginBottom: "20px" }}
              onClick={() => addToCalander()}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: "10px" }}
              >
                <path
                  d="M1 3.12598H17V17.3371H1V3.12598Z"
                  stroke="white"
                  strokeLinecap="square"
                />
                <path d="M17 6.67871H1" stroke="white" strokeLinecap="square" />
                <path
                  d="M4.55566 3.126V1.34961"
                  stroke="white"
                  strokeLinecap="square"
                />
                <path
                  d="M13.4443 3.126V1.34961"
                  stroke="white"
                  strokeLinecap="square"
                />
                <path
                  d="M11.6663 12.0078H6.33301"
                  stroke="white"
                  strokeLinecap="square"
                />
                <path
                  d="M9 9.34375V14.6729"
                  stroke="white"
                  strokeLinecap="square"
                />
              </svg>
              Add to Calender
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ShopDetailsServices;
