import React, { useState } from "react";
import "./Forget.style.scss";
import { HttpCallPost } from "../../services/NetworkCall";
import { CUSTOMER_FORGOT_PASSWORD } from "../../services/EndPoints";
import { ToastOnSuccess, ToastOnFailure } from "../../Shared/Toast/Toast";
import { useNavigate } from "react-router-dom";
import { VerifyPath } from "../../routes/RouterPath";
import Spinner from "../../Shared/Spinner/Spinner";

function ForgetPassword() {
  const [mobileNumber, setMobileNumber] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const forgotPasswordApi = (e:any) => {
    e.preventDefault()
    setIsSubmit(true);

    if (mobileNumber && mobileNumber.length >= 10 && !isNaN(parseInt(mobileNumber))) {
      setLoading(true);

      let data = {
        phoneNumber: mobileNumber,
        deviceType: "Web",
        deviceToken: "123456",
      };

      HttpCallPost(CUSTOMER_FORGOT_PASSWORD, data)
        .then((response: any) => {
          setLoading(false);
          if (response?.data?.status === 200) {
            ToastOnSuccess(response.data.msg);
            navigate(VerifyPath, {
              state: {
                customerForgotPassMobileNumber: mobileNumber,
                customerForgotPassID: response?.data?.userId,
                reDirectionUrl: "/reset-password",
              },
              replace: true,
            });
          }
          if (response?.data?.status === 400) {
            ToastOnFailure(response.data.msg);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const signupRedirection = () => {
    navigate("/login?tab=2", { replace: true });
  };

  const phoneNumberCheck = (evt: any) => {
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return true && evt.preventDefault();

    return false && evt.preventDefault();
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <form method="dialog" onSubmit={(e:any)=>forgotPasswordApi(e)}>
              <div className="forgotten-password-section">
                <div className="mobileNumber">
                  <h4 className="enter-mobile-heading">
                    Enter your mobile number registered <br /> with QSkipper
                  </h4>
                </div>
                <div className="phone-number-feild">
                  <div className="form-group">
                    <label htmlFor="exampleInputPhone Number1">
                      Phone Number
                    </label>
                    <input
                      maxLength={11}
                      type="text"
                      className="form-control number2"
                      id="exampleInputPhone Number1"
                      placeholder="Enter mobile number"
                      value={mobileNumber}
                      onKeyPress={(event) => phoneNumberCheck(event)}
                      onChange={(e: any) => setMobileNumber(e.target.value)}
                    />
                    {isSubmit && mobileNumber.length === 0 ? (
                      <div style={{ color: "red" }}>
                        Please enter your mobile number.
                      </div>
                    ) : (
                      <></>
                    )}
                    {isSubmit &&
                    mobileNumber.length > 0 &&
                    mobileNumber.length < 10 || mobileNumber.length > 0 && isNaN(parseInt(mobileNumber)) ? (
                      <div style={{ color: "red" }}>
                        Please enter a valid phone number.
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  &nbsp;
                  <div className="sign-up-btn-section">
                    <a>
                      <button className="signning-btn" type="submit">
                        Submit
                      </button>
                    </a>
                    <h6 className="dontHaveAccount-heading">
                      Don't have account? &nbsp;
                      <span>
                        <a
                          className="submit"
                          onClick={() => signupRedirection()}
                        >
                          Sign Up
                        </a>
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {loading ? <Spinner></Spinner> : <></>}
      </div>
    </>
  );
}

export default ForgetPassword;
