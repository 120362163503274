import { useEffect, useState } from "react";
import { BARBER_COLLEAGE_LIST } from "../../../services/EndPoints";
import { HttpCallGet } from "../../../services/NetworkCall";
import "./ShopDetailsColleagues.style.scss";
import Carousel from "react-elastic-carousel";
import StarRatingComponent from "react-star-rating-component";
import Modal from "react-bootstrap/Modal";
import { ratingConvert } from "../shopDetailsRatings/ShopDetailsRatings";
import SelectedTick from "../../../assets/selected-icon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { getProviderIdFromUrl } from "../../../utils/filter";
import { ToastOnFailure } from "../../../Shared/Toast/Toast";
interface Props {
  data: any;
  prize:any;
  setPrize:any;
  serviceSelected?:any;
  setServiceSelected?:any;
}

const breakPoints = [
  { width: 1, itemsToShow: 3 },
  { width: 550, itemsToShow: 3 },
  { width: 768, itemsToShow: 4 },
  { width: 1200, itemsToShow: 4 },
];

const defaultViewForPhones = [
  { width: 1, itemsToShow: 3 },
  { width: 550, itemsToShow: 3 },
  { width: 768, itemsToShow: 4 },
];

export const ShopDetailsColleagues = ({ data ,prize, setPrize ,serviceSelected, setServiceSelected }: Props) => {
  const [colleagues, setColleagues] = useState<any>([]);
  const [isReadMore, setIsReadMore] = useState(true);
  const [getDynamicValue, setGetValueDynamic] = useState<any>();
  let providerId = "";
  let detailBarber: any = {};
  let redirectToaster: any = null;
  const { pathname } = useLocation();
  const urlId = getProviderIdFromUrl(pathname);

  if (sessionStorage.getItem("barberDetails") != null) {
    detailBarber = JSON.parse(sessionStorage.getItem("barberDetails") || "{}");
    providerId = detailBarber?.colleagueId;
  }
  useEffect(() => {
    window.addEventListener("resize", () => {
      setGetValueDynamic(window.innerWidth);
    });
  }, [getDynamicValue]);

  const navigate = useNavigate();

  useEffect(() => {
    if (providerId) {
      FetchBarberList(providerId);
    }
  }, [providerId]);

  const FetchBarberList = (id: any) => {
    HttpCallGet(
      `${BARBER_COLLEAGE_LIST}/${id}?page=1&limit=50`,
      sessionStorage.getItem("AUTHTOKEN_USER")
    )
      .then((response: any) => {

        detailBarber.isBlock = response?.data?.data?.isBlock;
        detailBarber.isActive = response?.data?.data?.isActive;
        sessionStorage.setItem("barberDetails", JSON.stringify(detailBarber));

        let colleagues = [
          ...[detailBarber],
          ...response?.data?.data?.colleagueDetails,
        ];
        setColleagues(colleagues);
      })
      .catch((err: any) => {
        console.log(err.message);
      });
  };

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const naviagteToColleaguesDetail = (value: any) => {
    if (value.isActive && value.isProfileCompleted && !value.isBlock) {
      setPrize(0)
      setServiceSelected([])      
      navigate(`/barber/${value?.colleagueId}`);
    } 

    // else {
    //   if (redirectToaster) {
    //     clearTimeout(redirectToaster);
    //   }

    //   redirectToaster = setTimeout(() => {
    //     ToastOnFailure("Barber is not available at the moment.");
    //   }, 500);
    // }
  };
  const handleBeforeUnload = (event:any) => {
    event.preventDefault();
    event.returnValue = ''; // Chrome requires a return value to display the confirmation dialog
  };

  const handleBackButton = () => {
    navigate(1)
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [navigate]);

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            {data?.BussinessInfo ? (
              <p className="colleaguesPara">
                {isReadMore
                  ? data?.BussinessInfo.length > 250
                    ? data?.BussinessInfo.slice(0, 250) + "..."
                    : data?.BussinessInfo.slice(0, 250)
                  : data?.BussinessInfo}
                {data?.BussinessInfo.length > 250 &&
                  data?.BussinessInfo.length < 501 ? (
                  <span onClick={toggleReadMore} className="read-more">
                    {isReadMore ? "Read More" : "Show Less"}
                  </span>
                ) : (
                  <></>
                )}
              </p>
            ) : (
              <></>
            )}
            {colleagues.length > 1 ? (
              <>
                <h2 className="colleagues">Barbers</h2>
                <h6 className="choose-heading">Choose your barber</h6>
              </>
            ) : null}
          </div>
        </div>
      </div>

      {colleagues.length > 1 ? (
        <div className="wrapper">
          <Carousel
            isRTL={false}
            breakPoints={
              window.innerWidth > 768 ? breakPoints : defaultViewForPhones
            }
            enableMouseSwipe={window.innerWidth > 768 ? false : true}
            showArrows={window.innerWidth > 768 ? true : false}
          >
            {colleagues.length > 0 ? (
              colleagues.map((item: any, index: any) => {
                const { name, profilePic, rating, reviewCount } = item;
                return (
                  <div key={index}>
                    <div className="removeSpace">
                      <a>
                        <div>
                          <div className="text-center colleguesActive">
                            {!profilePic ||
                              profilePic === null ||
                              profilePic == "" ? (
                              <>
                                {urlId === item?.colleagueId ? (
                                  <img
                                    className="selected-icon"
                                    src={SelectedTick}
                                    alt=""
                                  />
                                ) : (
                                  <></>
                                )}
                                <div
                                  className={
                                    urlId === item?.colleagueId
                                      ? "custom-profileImage img-colleague-active"
                                      : "custom-profileImage"
                                  }
                                  onClick={() =>
                                    naviagteToColleaguesDetail(item)
                                  }
                                >
                                  {name[0].toUpperCase()}
                                </div>
                              </>
                            ) : (
                              <>
                                {urlId === item?.colleagueId ? (
                                  <img
                                    className="selected-icon"
                                    src={SelectedTick}
                                    alt=""
                                  />
                                ) : (
                                  <></>
                                )}
                                <img
                                  onClick={() =>
                                    naviagteToColleaguesDetail(item)
                                  }
                                  className={
                                    urlId === item?.colleagueId
                                      ? "img-colleague img-colleague-active"
                                      : "img-colleague"
                                  }
                                  src={
                                    process.env.REACT_APP_MEDIA_BASE_URL +
                                    profilePic
                                  }
                                  alt="s"
                                />
                              </>
                            )}
                          </div>
                          <div>
                            <h3 className="christoper-name">{name}</h3>
                            <div className="slider-star">
                              <StarRatingComponent
                                name="rate1"
                                starCount={5}
                                value={ratingConvert(rating)}
                              />
                            </div>
                            <h6 className="review"> {reviewCount} Review(s)</h6>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                );
              })
            ) : (
              <h2>Loading....</h2>
            )}
          </Carousel>

          {/* <!-- end slider --> */}
        </div>
      ) : (
        <></>
      )}

      {colleagues && colleagues.length > 1 ? (
        <MyVerticallyCenteredModal
          useNavigate={useNavigate}
          data={colleagues}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

/// model component start from here /////
interface propsI {
  data: any;
  useNavigate: any;
}

function MyVerticallyCenteredModal({ data, useNavigate }: propsI) {
  const [showModal, setShowModal] = useState<any>(false);
  const handleClose = () => setShowModal(false);
  const showColleaguesPopUp =
    sessionStorage.getItem("showColleaguesPopUp") == null
      ? {}
      : JSON.parse(sessionStorage.getItem("showColleaguesPopUp") || "{}");

  let providerId = "";
  let detailBarber: any = {};
let redirectToaster: any = null;
  if (sessionStorage.getItem("barberDetails") != null) {
    detailBarber = JSON.parse(sessionStorage.getItem("barberDetails") || "{}");
    providerId = detailBarber?.colleagueId;
  }
  const { pathname } = useLocation();
  const urlId = getProviderIdFromUrl(pathname);
  const navigate = useNavigate();
  useEffect(() => {
    if (!showColleaguesPopUp.hasOwnProperty(providerId)) {
      setShowModal(true);
      showColleaguesPopUp[providerId] = false;
      sessionStorage.setItem(
        "showColleaguesPopUp",
        JSON.stringify(showColleaguesPopUp)
      );
    }
  }, []);

  const naviagteToColleaguesDetail = (value: any) => {
    if (value.isActive && value.isProfileCompleted && !value.isBlock) {
      handleClose();
      navigate(`/barber/${value?.colleagueId}`);
    } 
// else {
    //   if (redirectToaster) {
    //     clearTimeout(redirectToaster);
    //   }

    //   redirectToaster = setTimeout(() => {
    //     ToastOnFailure("Barber is not available at the moment.");
    //   }, 500);
    // }
  };

  return (
    <>
      {showModal != null && showModal && (
        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showModal}
          onHide={handleClose}
          backdrop="static"
        >
          {/* <Modal.Header closeButton></Modal.Header> */}
          <Modal.Body>
            <div className="colleaguPopup">
              <h3 className="colleguesModalHeading">Barbers</h3>
              <h6 className="modal-chooseBarber">Choose your barber</h6>
              <Carousel isRTL={false} breakPoints={breakPoints}>
                {data.length > 0 ? (
                  data.map((item: any, index: any) => {
                    const { name, profilePic, rating, reviewCount } = item;
                    return (
                      <div key={index}>
                        <div>
                          <a>
                            <div>
                              <div className="text-center colleguesActive">
                                {!profilePic ||
                                  profilePic === null ||
                                  profilePic == "" ? (
                                  <>
                                    <div
                                      className="custom-profileImage"
                                      onClick={() =>
                                        naviagteToColleaguesDetail(item)
                                      }
                                    >
                                      {name[0].toUpperCase()}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      onClick={() =>
                                        naviagteToColleaguesDetail(item)
                                      }
                                      className="img-colleague"
                                      src={
                                        process.env.REACT_APP_MEDIA_BASE_URL +
                                        profilePic
                                      }
                                      alt="s"
                                    />
                                  </>
                                )}
                              </div>
                              <div>
                                <h3 className="christoper-name">{name}</h3>
                                <div className="slider-star">
                                  <StarRatingComponent
                                    name="rate1"
                                    starCount={5}
                                    value={ratingConvert(rating)}
                                  />
                                </div>
                                <h6 className="review">
                                  {reviewCount} Review(s)
                                </h6>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h2>Loading ....</h2>
                )}
              </Carousel>

              {/* <!-- end slider --> */}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
