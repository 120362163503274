import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { qSkipperLogoBlack } from "../../utils/Images";
import "./Header.style.scss";
import appleStoreImage from "../../assets/Apple-Appstore-logo.png";
import GoogleStoreImage from "../../assets/Google_Play-Logo 2.png";
import qSkipperLogoWhite from "../../assets/MobileViewLogo01.png";
import { HttpCallGet } from "../../services/NetworkCall";
import { CUSTOMER_DETAILS } from "../../services/EndPoints";
import { Offcanvas } from "react-bootstrap";
import AddLogo from "../../assets/Addlogo-icon2.png";
import { getProviderIdFromUrl } from "../../utils/filter";
interface Props { }

declare global {
  interface Window {
    HubSpotConversations: any;
  }
}

const Header = ({ }: Props) => {
  const [showProfile, setShowProfile] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [customerDetail, setCustomerDetail] = useState({
    profilePic: "",
    name: "",
    phoneNumber: "",
  });

  const token = sessionStorage.getItem("AUTHTOKEN_USER");

  const [showAppDownloadPopup, setShowAppDownloadPopup] = useState<any>(false);
  const userLoginFlag = sessionStorage.getItem("USER_ID") ? true : false;

  const buttonStatus =
    location.pathname.includes("barber") ||
    location.pathname.includes("payment");

  const [showLoginSignupButtons, setShowLoginSignupButtons] = useState(false);

  const [show, setShow] = useState(false);
  const signupRedirection = () => {
    sessionStorage.removeItem("AUTHTOKEN_USER");
    sessionStorage.removeItem("USER_ID");
    navigate("/login?tab=2", { replace: true });
    setShow(false);
  };

  const signinRedirection = () => {
    sessionStorage.removeItem("AUTHTOKEN_USER");
    sessionStorage.removeItem("USER_ID");
    setShowProfile(false);

    if (location.pathname.includes("payment")) {
      const previousState = sessionStorage.getItem("reDirectionUrl") || "";
      sessionStorage.setItem("previousUrl", previousState);
    } else {
      const previousState = location.pathname;
      sessionStorage.setItem("previousUrl", previousState);
    }
    setShow(false);
    navigate("/login?tab=1", { replace: true });
  };

  const hamburgerMenuHandler = () => {
    setShow(true);
  };

  useEffect(() => {
    setShowLoginSignupButtons(buttonStatus);
  }, [buttonStatus]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
    if (sessionStorage.getItem("USER_ID")) {
      fetchCustomerDetails();
    }
  }, [location]);

  useEffect(() => {
    const handleDocumentMouseUp = (event: any) => {
      const concernedElement: any = document.querySelector(
        ".dropdownProfileMenu"
      );

      if (concernedElement && !concernedElement.contains(event.target)) {
        setShowProfile(false);
      }
    };

    document.addEventListener("mouseup", handleDocumentMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleDocumentMouseUp);
    };
  }, []);

  const showDownloadPopUp = sessionStorage.getItem("showDownloadPopUp");

  useEffect(() => {
    if (!showDownloadPopUp) {
      setShowAppDownloadPopup(true);
      sessionStorage.setItem("showDownloadPopUp", "false");
    }
  }, []);

  const fetchCustomerDetails = () => {
    HttpCallGet(`${CUSTOMER_DETAILS}`, token)
      .then((response: any) => {
        setCustomerDetail({
          profilePic: response?.data?.data?.profilePic,
          name: response?.data?.data?.name,
          phoneNumber: response?.data?.data?.phoneNumber,
        });
      })
      .catch((err: any) => {
        console.log(err.message);
      });
  };

  const handleClose = () => {
    setShow(false);
  };

  const closeAppDownloadPopup = () => {
    setShowAppDownloadPopup(false);
  };

  const showHubSpot = () => {
    setShow(false);
    window.HubSpotConversations.widget.open();
  };

  return (
    <header>
      <div className="background">
        <div className="container">
          <nav id="navbar">
            <div className="nav-wrapper">
              <div className="logo">
                <a className="navbar-brand"  href="https://qskipper.app" target="_blank">
                  <img className="navbar-logo"  src={qSkipperLogoBlack} alt="" />
                </a>
                <a
                  className="navbar-brand-white"
                  href="https://qskipper.app" target="_blank"
                >
                  <img className="navbar-logo-white" src={qSkipperLogoWhite} />
                </a>
              </div>

              {showLoginSignupButtons ? (
                <>
                  <ul id="menu">
                    {!userLoginFlag ? (
                      <>
                        <li>
                          <button
                            className="btn my-2 my-sm-0 signin"
                            type="button"
                            onClick={() => signupRedirection()}
                          >
                            Sign Up
                          </button>
                        </li>
                        <li>
                          <button
                            className="btn my-2 my-sm-0 Login"
                            type="button"
                            onClick={() => signinRedirection()}
                          >
                            Log In
                          </button>
                        </li>
                      </>
                    ) : (
                      <div className="header-icon">
                        {customerDetail?.profilePic ? (
                          <img
                            className="profile-image"
                            src={
                              process.env.REACT_APP_MEDIA_BASE_URL +
                              customerDetail?.profilePic
                            }
                            onClick={() => setShowProfile(!showProfile)}
                            alt=""
                          />
                        ) : (
                          <div
                            id="profileImage"
                            onClick={() => setShowProfile(!showProfile)}
                          >
                            {customerDetail?.name.length > 0
                              ? customerDetail?.name[0].toUpperCase()
                              : ""}
                          </div>
                        )}
                        {showProfile ? (
                          <div className="dropdownProfileMenu">
                            <div className="profile-part">
                              <div className="Profile-section">
                                {customerDetail?.profilePic ? (
                                  <img
                                    className="tab-profile-image"
                                    src={
                                      process.env.REACT_APP_MEDIA_BASE_URL +
                                      customerDetail?.profilePic
                                    }
                                    alt=""
                                  />
                                ) : (
                                  <div id="tabProfileImage">
                                    {customerDetail?.name.length > 0
                                      ? customerDetail?.name[0].toUpperCase()
                                      : ""}
                                  </div>
                                )}
                                <h2 className="Profile-F-heading">
                                  {customerDetail?.name}
                                </h2>
                                <h3 className="profile-number-heading">
                                  {customerDetail?.phoneNumber}
                                </h3>
                                {/* <button className="manage-prefrence">
                                  Manage Your preferences
                                </button> */}
                              </div>
                              <hr className="profileHR" />

                              <div className="logout-btn-section text-center">
                                <button
                                  type="button"
                                  className="btn profile-logout-button"
                                  onClick={() => signinRedirection()}
                                >
                                  Log Out
                                </button>
                                <hr className="profileHR" />
                              </div>

                              <form>
                                <div className="modal-footer text-center">
                                  <h4 className="Download-app">
                                    Download the app!
                                  </h4>
                                  <h6 className="more-feature-heading">
                                    Manage your account and preferences by downloading the mobile app
                                  </h6>
                                  <div className="available-on">
                                    <a
                                      href="https://apps.apple.com/us/app/qskipper/id1566103523"
                                      target="_blank"
                                    >
                                      <img
                                        className="apple-Store-Image"
                                        src={appleStoreImage}
                                        alt=""
                                      />
                                    </a>
                                    <a
                                      href="https://play.google.com/store/apps/details?id=app.qskipper"
                                      target="_blank"
                                    >
                                      <img
                                        className="Google-Store-Image"
                                        src={GoogleStoreImage}
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </ul>
                </>
              ) : (
                <></>
              )}
            </div>
          </nav>
        </div>
      </div>
      <div className="menuIcon">
        <a onClick={() => hamburgerMenuHandler()}>
          <span className="icon icon-bars"></span>
        </a>
      </div>
      <div className="thinLine desktop-view" />

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="start"
        style={{ width: "85%", padding: "25px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul id="menu">
            {showLoginSignupButtons ? (
              <>
                {!userLoginFlag ? (
                  <>
                    <li>
                      <button
                        className="btn btn-success my-2 my-sm-0 signin"
                        type="button"
                        onClick={() => signupRedirection()}
                      >
                        Sign Up
                      </button>
                    </li>
                    <li>
                      <button
                        className="btn btn btn-dark my-2 my-sm-0 Login"
                        type="button"
                        onClick={() => signinRedirection()}
                      >
                        Log In
                      </button>
                    </li>
                  </>
                ) : (
                  <>
                    <div className="profile-part">
                      <div className="Profile-section">
                        {customerDetail?.profilePic ? (
                          <img
                            className="tab-profile-image"
                            src={
                              process.env.REACT_APP_MEDIA_BASE_URL +
                              customerDetail?.profilePic
                            }
                            alt=""
                          />
                        ) : (
                          <div id="tabProfileImage">
                            {customerDetail?.name.length > 0
                              ? customerDetail?.name[0].toUpperCase()
                              : ""}
                          </div>
                        )}
                        <h2 className="Profile-F-heading">
                          {customerDetail?.name}
                        </h2>
                        <h3 className="profile-number-heading">
                          {customerDetail?.phoneNumber}
                        </h3>
                      </div>
                      <div className="logout-btn-section text-center mt-4">
                        <button
                          type="button"
                          className="btn profile-logout-button"
                          onClick={() => signinRedirection()}
                        >
                          Log Out
                        </button>
                        <hr className="profileHR" />
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
            
            <a className="humburger-anchor" onClick={() => showHubSpot()}>
              Contact Us
            </a>

            <a
              className="humburger-anchor"
              href="https://qskipper.app/terms-and-conditions"
              target="_blank"
            >
              Terms & Conditions
            </a>
            <a
              className="humburger-anchor"
              href="https://qskipper.app/return-policy"
              target="_blank"
            >
              Refund Policy
            </a>
            <a
              className="humburger-anchor"
              href="https://qskipper.app/privacy-notice"
              target="_blank"
            >
              Privacy Notice
            </a>
            <a
              className="humburger-anchor"
              href="https://qskipper.app/cookie-policy"
              target="_blank"
            >
              Cookie Policy
            </a>
            <a
              className="humburger-anchor"
              href="https://qskipper.app/disclaimer"
              target="_blank"
            >
              Disclaimer
            </a>

            <div className="nav-downloading-section">
              <h5 className="nav-download-heading">Download the app!</h5>
              <p className="nav-para">
                There are more features (listed below) <br />
                available on the app.
              </p>
              <div className="nav-app-downloader">
                <a
                  href="https://apps.apple.com/us/app/qskipper/id1566103523"
                  target="_blank"
                  onClick={() => setShow(false)}
                >
                  <img className="nav-apple-image" src={appleStoreImage} />
                </a>
                <a
                  onClick={() => setShow(false)}
                  href="https://play.google.com/store/apps/details?id=app.qskipper"
                  target="_blank"
                >
                  <img className="nav-google-image" src={GoogleStoreImage} />
                </a>
              </div>
            </div>

            <div className="humburger-nav-Details">
              <h6 className="humburger-nav-heading">Edit Profile</h6>
              <h6 className="humburger-nav-heading">Reschedule Appoinments</h6>
              <h6 className="humburger-nav-heading">Cancel Appoinments</h6>
              <h6 className="humburger-nav-heading">Payment History</h6>
              <h6 className="humburger-nav-heading">Favourites</h6>
              <h6 className="humburger-nav-heading">Promo Codes</h6>
              <h6 className="humburger-nav-heading">Notifications</h6>
              <h6 className="humburger-nav-heading">Saved Cards</h6>
            </div>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
      {showAppDownloadPopup != null && showAppDownloadPopup ? (
        <div className="AddScreenSec">
          <div className="AddDetails">
            <div
              className="cutSec"
              onClick={() => closeAppDownloadPopup()}
            ></div>
            <div className="AddLogo">
              <img src={AddLogo} className="AddLogoIcon" alt="" />
            </div>
            <div className="AddHeadingSec">
              <h5 className="addHeading">QSkipper</h5>
              <h6 className="AddSmallheading">Mobile app</h6>
            </div>
          </div>
          <div className="AddDownload">
            <a
              href="https://play.google.com/store/apps/details?id=app.qskipper"
              target="_blank"
            >
              <button className="DownloadBtn">Download</button>
            </a>
          </div>
        </div>
      ) : (
        <></>
      )}
    </header>
  );
};
export default Header;
