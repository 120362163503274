import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Calender.style.scss";
import moment from "moment";

interface Props {
  selectedData?: any;
  newDate?: any;
  maxDate?: any;
  schedules?: any;
}

function Calender({ selectedData, newDate, maxDate, schedules }: Props) {
  let disabledDates: any = [];

  const datesBlacklistFunc = (date: any) => {
    const dataArray = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];

    const dataObj: any = {
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
      sunday: 7,
    };

    let openingDays = schedules;
    const lt: any = [];

    for (const val of dataArray) {
      if (openingDays[val].length == 0) {
        const num = dataObj[val];
        const result = lt.includes(num);
        if (result != null) {
          lt.push(num);
        }
      }
    }

    return lt.includes(moment(date).isoWeekday());
  };

  const start = new Date();
  const end = new Date(moment(maxDate).add(1, "d").format("YYYY-MM-DD"));
  let loop = new Date(start);
  while (loop <= end) {
    if (datesBlacklistFunc(loop)) {
      disabledDates.push(new Date(loop));
    }
    let newDate = loop.setDate(loop.getDate() + 1);
    loop = new Date(newDate);
  }

  return (
    <div>
      {/* for styling we can directly use className in this library  or we can reference with this project for styling ==> https://codesandbox.io/s/z7m3w?file=/src/App.tsx:1149-1160 */}

      <Calendar
        next2Label={null}
        prev2Label={null}
        className="calender-View"
        onChange={(e: any) => {
          newDate(e);
          const scrollFromCalender = document.querySelector(".calender-tag");
          scrollFromCalender?.scrollBy({ top: 200, behavior: "smooth" });
        }}
        value={selectedData}
        minDate={new Date()}
        maxDate={maxDate}
        tileDisabled={({ date, view }) =>
          view === "month" && // Block day tiles only
          disabledDates.some(
            (disabledDate: any) =>
              date.getFullYear() === disabledDate.getFullYear() &&
              date.getMonth() === disabledDate.getMonth() &&
              date.getDate() === disabledDate.getDate()
          )
        }
      ></Calendar>
    </div>
  );
}
export default Calender;
