import StarRatingComponent from "react-star-rating-component";
// import { icClock, icLocationOrange, icPhone } from "../../../utils/Images";
import "./ShopDetailsHeader.style.scss";
import NewClock from "../../../assets/NewClockPNG2.png";
import moment from "moment";
interface Props {
  data: any;
}
const avgRating = (num: string) => {
  return parseInt(num);
};
const ShopDetailsHeader = ({ data }: Props) => {
  const schedules = data.schedules != undefined ? data.schedules : {};
  const avability = schedules[moment().format("dddd").toLowerCase()];

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-7">
          <div className="main-pageHeader">
            <div className="imSection">
              {data?.profilePic ? (
                <img
                  className="profile-img"
                  src={process.env.REACT_APP_MEDIA_BASE_URL + data?.profilePic}
                  alt=""
                />
              ) : (
                <div className="profileImage-main">
                  {data?.name ? data?.name[0].toUpperCase() : ""}
                </div>
              )}
            </div>
            <div className="main-pageHeading">
              <h2 className="name-heading">
                {data?.name} - {data?.bussinessName}
              </h2>

              {data?.reviews &&
                data?.reviews === null &&
                data?.reviews == "" ? null : (
                <div className="rating-Section">
                  <a
                    href={
                      data?.reviews?.reviews > 0
                        ? "#Footer-comment"
                        : "javascript:;"
                    }
                  >
                    <StarRatingComponent
                      name="rate1"
                      starCount={5}
                      value={avgRating(data?.reviews?.averageRating)}
                    />
                  </a>
                  <a
                    href={
                      data?.reviews?.reviews > 0
                        ? "#Footer-comment"
                        : "javascript:;"
                    }
                  >
                    <h2 className="reviews">
                      (
                      <span className="RedirectFooter">
                        <span style={{ textDecoration: "underline" }}>
                          {data?.reviews?.reviews} Reviews
                        </span>
                      </span>
                      )
                    </h2>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-5">
          <div className="detail-section">
            {data?.address ? (
              <div className="location-part">
                <span className="location">
                  {/* <i className="fa fa-map-marker" aria-hidden="true"></i> */}
                  <svg
                    width="18"
                    height="25"
                    viewBox="0 0 18 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 21C14.3333 16.3333 17 11.8889 17 7.66667C17 5.89856 16.1571 4.20286 14.6569 2.95262C13.1566 1.70238 11.1217 1 9 1C6.87827 1 4.84344 1.70238 3.34315 2.95262C1.84286 4.20286 1 5.89856 1 7.66667C1 11.8889 3.66667 16.3333 9 21Z"
                      stroke="#F47321"
                      stroke-miterlimit="8"
                      stroke-linecap="square"
                    />
                    <path
                      d="M9 11C10.1046 11 11 10.1046 11 9C11 7.89543 10.1046 7 9 7C7.89543 7 7 7.89543 7 9C7 10.1046 7.89543 11 9 11Z"
                      stroke="#FD6A02"
                      stroke-miterlimit="8"
                      stroke-linecap="square"
                    />
                  </svg>
                </span>
                <h4 className="location-heading">{data?.address}</h4>
              </div>
            ) : null}

            <div className="time-part">
              <span className="time">
                {/* <i className="fa fa-clock-o" aria-hidden="true"></i> */}
                <img className="clockIcon" src={NewClock} alt="" />

              </span>

              {avability && avability.length > 0 ? (
                <h4 className="time-heading">
                  <div className="OpenTodayHeading">Open Today:</div>&nbsp;
                  <div>
                    {avability.map((res: any) => {
                      return (
                        <>
                          {res} <br />
                        </>
                      );
                    })}
                  </div>
                </h4>
              ) : (
                <h4 className="time-heading">Closed Today</h4>
              )}
            </div>
            {data?.phoneNumber && data.phoneNumber.trim().length > 0 ? (
              <div className="phone-part">
                <span className="phone">
                  <svg
                    width="13"
                    height="20"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.8 1.9V18.1C11.8 18.5971 11.3971 19 10.9 19H1.9C1.40294 19 1 18.5971 1 18.1V1.9C1 1.40294 1.40294 1 1.9 1H10.9C11.3971 1 11.8 1.40294 11.8 1.9Z"
                      stroke="#F47321"
                      stroke-linecap="square"
                    />
                    <path
                      d="M8.2001 1V1.9H4.6001V1H8.2001Z"
                      stroke="#F47321"
                      stroke-linecap="square"
                    />
                  </svg>
                </span>
                <h4 className="number">{data?.phoneNumber}</h4>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopDetailsHeader;
