import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import {
  CUSTOMER_ADD_CARD,
  CUSTOMER_SAVE_CARD,
} from "../../services/EndPoints";
import { HttpCallGet, HttpCallPost } from "../../services/NetworkCall";
import Spinner from "../../Shared/Spinner/Spinner";
import { ToastOnFailure, ToastOnSuccess } from "../../Shared/Toast/Toast";
import "./StripePay.style.scss";

let STRIPE_SECRET_KEY = process.env.REACT_APP_STRIPE_SECRET_KEY || "";

const stripePromise = loadStripe(STRIPE_SECRET_KEY);

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "#f06f1f",
      lineHeight: "27px",
      borderRadius: "10px",
      color: "#404a43",
      bordercolor: "blue",
      fontWeigth: "Regular",
      fontSize: "1.4rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "red",
      iconColor: "#fa755a",
    },
  },
};

export const PaymentForm = (props: any) => {
  const [userId, setUserId] = useState<any>();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const addCard = (stripe: any, elements: any) => async () => {
    const cardElement = elements.getElement(CardElement);
    const result = await stripe.createToken(cardElement);
    if (userId && result?.token?.id) {
      const defaultData = {
        stripeCustomerId: userId,
        tokenId: result?.token?.id,
      };
      setLoading(true);
      HttpCallPost(
        CUSTOMER_SAVE_CARD,
        defaultData,
        sessionStorage.getItem("AUTHTOKEN_USER")
      )
        .then((response: any) => {
          setLoading(false);
          if (response?.data?.status === 200) {
            ToastOnSuccess("card succesfully added");
            props.getAgainCardDetails();
            cardElement.clear();
            props.clearCardDetails();
          }
          if (response?.data?.status === 400) {
            ToastOnFailure(response.data.msg);
          }
        })
        .catch((error) => {
          setLoading(false);
          ToastOnFailure(error);
        });
    }
  };

  const GetNewUserForCard = () => {
    HttpCallGet(CUSTOMER_ADD_CARD, sessionStorage.getItem("AUTHTOKEN_USER"))
      .then((response: any) => {
        if (response?.data?.status === 200) {
          setUserId(response?.data?.data[0]?.id);
        }
        if (response?.data?.status === 400) {
          ToastOnFailure(response.data.msg);
        }
      })
      .catch((error) => {
        ToastOnFailure(error);
      });
  };

  useEffect(() => {
    GetNewUserForCard();
  }, []);
  return (
    <div className="Checkout">
      <CardElement options={CARD_ELEMENT_OPTIONS} />
      <div className="stripMainDiv">
        <span className="stripMainSpan1" ></span>

      </div>
      <button
        className=" btn btn-outline-primary addCard-button extra"
        onClick={addCard(stripe, elements)}
      >
        Add Card
      </button>
      {loading ? <Spinner></Spinner> : <></>}
    </div>
  );
};
export const StripePaymentForm = (props: any) => (
  <>
    <Elements stripe={stripePromise}>
      <PaymentForm
        getAgainCardDetails={props.getAgainCardDetails}
        clearCardDetails={props.clearCardDetails}
      />
    </Elements>
  </>
);
