export const LOGIN: String = "customer/signIn";
export const SIGNUP: String = "customer/signUp";
export const GET_COUNTRY: String = "customer/country";
export const RESEND_OTP: String = "customer/resendOtp";
export const SEND_OTP: String = "customer/verify-Phone";
export const RESET_PASSWORD: String = "customer/resetPassword";

export const TERMS_AND_CONDITIONS =
  "https://qskipper.app/terms-and-conditions";

export const CUSTOMER_DETAILS: string = `customer/getProfileDetails`; 

export const CUSTOMER_FORGOT_PASSWORD: String = "customer/forgetPassword";

export const BARBER_COLLEAGE_LIST: String = `customer/barber-details`;

export const BARBER_DETAILS: string = `customer/details`;

export const ADD_GET_CARD_STRIPE = `customer/stripe/card-add-get`;

export const DELETE_CARD_STRIPE = `customer/stripe/card-delete`;

export const CUSTOMER_DATE_BOOKINGS = 'customer/date-bookings';

export const CUSTOMER_ADVANCE_STRIPE_PAYMENT = `customer/stripe/create-stripe-advance-payment`;

export const BARBER_LIST = `customer/search-barber`;

export const CUSTOMER_BOOKING_CONFIRM = `customer/booking-confirm`;

export const CUSTOMER_ADD_CARD = `customer/stripe/addCustomer`;

export const CUSTOMER_SAVE_CARD= "customer/stripe/saverCard";

export const CUSTOMER_GET_PROFILE_DETAILS= `customer/getProfileDetails`;

